import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import axios from "axios";
import Swal from 'sweetalert2'
import DatePicker from "react-datepicker";
import ReactSelect from 'react-select'
import homeIcon from "../custom_img/home_icon.png";
import '../custom_css/profile_edit.scss';
import 'react-datepicker/dist/react-datepicker.css';

const API_URL = "https://poborowy.pl/";

const startDate = value => {
  if (value && value.toISOString().split('T')[0].match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/) === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną datę w postaci RRRR-MM-DD
      </div>
    );
  }
};

const endDate = value => {
  if (value && value.toISOString().split('T')[0].match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/) === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną datę w postaci RRRR-MM-DD
      </div>
    );
  }
};

export default class AdminTrainingCreate extends Component {
  constructor(props) {
    super(props);
    this.handleTrainingCreate = this.handleTrainingCreate.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onChangeSection = this.onChangeSection.bind(this);
    this.onChangeVerification = this.onChangeVerification.bind(this);
    this.onChangeFacility = this.onChangeFacility.bind(this);
    this.onChangeAdditionalInfo = this.onChangeAdditionalInfo.bind(this);
   
    this.state = {
      start_date: null,
      end_date: null,
      section: null,
      verification: null,
      facility: null,
      allSections: [],
      allVerifications: [],
      allFacilities: [],
      additionalInfo: null,
      url_id: window.location.pathname.split('/')[3],
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  onChangeStartDate(e) {
    this.setState({
      start_date: e
    });
  }

  onChangeEndDate(e) {
    this.setState({
      end_date: e
    });
  }

  onChangeSection(e) {
    this.setState({
      section: e.id
    });
  }

  onChangeVerification(e) {
    this.setState({
      verification: e.id
    });
  }

  onChangeFacility(e) {
    this.setState({
      facility: e.id
    });
  }

  onChangeAdditionalInfo(e) {
    this.setState({
      additionalInfo: e.target.value.replace(/\r?\n/g, '~~')
    });
  }

  handleTrainingCreate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
      loading: true
    });

    this.form.validateAll();

    var data = {
      date_from: this.state.start_date ? this.state.start_date.toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
      date_to: this.state.end_date ? this.state.end_date.toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
      section_id: parseInt(this.state.section),
      verification_id: parseInt(this.state.verification),
      facility_id: parseInt(this.state.facility),
      additional_info: this.state.additionalInfo
    }

    if (this.checkBtn.context._errors.length === 0) {
      axios.post(API_URL + "test/information", data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      }).then(
        axios_res => {
          if (this.state.name !== axios_res.data.trainings.data.attributes.name) {
            this.setState({
              message: this.handleError(axios_res),
              successful: false,
              error: true,
              loading: false
            });
          } else {
            this.setState({
              loading: false,
              redirect: '/admin/trainings'
            });
            Swal.fire({
              icon: "success",
              title: "Utworzono !",
              text: "Zjazd został poprawnie utworzony"
            });
          }
        },
        error => {
          const resMessage = error.response.data.details || error.response.data.message || error.toString();
          this.setState({
            successful: false,
            message: resMessage,
            error: true,
            loading: false
          });
        }
      );
    }
  }

  componentDidMount() {
    // GET USER DATA
    axios.get(API_URL + "user/information/" + this.state.currentUserId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.token,
        'Email': this.state.currentUserEmail
      },
    })
    .then(response => {
      this.setState({error: false});
      const currentUser = response.data.users.data.attributes;
      if (currentUser.id_role === 'regular') {
        const redirect = '/login'
        this.setState({ redirect });
      }
      const userReady = true;
      this.setState({ userReady });
      // GET ALL SECTION DATA
      axios.get(API_URL + "sections/information", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      })
      .then(response => {
        const { data: allSections = [] } = response.data.sections;
        this.setState({ allSections });
      })
      .catch(err => {
        if (err.response.status === 401) {
          const redirect = '/login'
          this.setState({ redirect });
        } else {
          const profileError = err;
          this.setState({ profileError });
        }
      })
      // GET ALL VERIFICATIONS DATA
      axios.get(API_URL + "verifications/information", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      })
      .then(response => {
        const { data: allVerifications = [] } = response.data.verifications;
        this.setState({ allVerifications });
      })
      .catch(err => {
        if (err.response.status === 401) {
          const redirect = '/login'
          this.setState({ redirect });
        } else {
          const profileError = err;
          this.setState({ profileError });
        }
      })
      // GET ALL FACILITIES DATA
      axios.get(API_URL + "locations/information", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      })
      .then(response => {
        const { data: allFacilities = [] } = response.data.facilities;
        this.setState({ allFacilities });
      })
      .catch(err => {
        if (err.response.status === 401) {
          const redirect = '/login'
          this.setState({ redirect });
        } else {
          const profileError = err;
          this.setState({ profileError });
        }
      })
    })
    .catch(err => {
      if (err.response.status === 401) {
        const redirect = '/login'
        this.setState({ redirect });
      } else if (err.response.status === 404) {
        this.setState({
          successful: false,
          profileError: `Wystąpił błąd lub nie znaleziono użytkownika z ID ${this.state.url_id}`,
          error: true
        });
      } else {
        const profileError = err;
        this.setState({ profileError });
      }
    })
  }

  handleError(err) {
    if (!err.hasOwnProperty('response')) {
      err.response = {
          data: {
            details: "Wystąpił nieoczekiwany błąd."
          }
       }
    }
    if (Object.keys(err.response.data.details).length !== 0) {
      var response_error = JSON.stringify(err.response.data.details);
      if (response_error) {
        response_error = response_error.slice(1, response_error.length-1).replace(/"/g, "")
        response_error = response_error.split(',')
        response_error = response_error.join(".  ");
        return response_error
      }
    } else {
      return err.response.data.message
    }
  }

  render() {
    const { userReady, profileError, redirect, start_date, end_date, allSections, allVerifications, allFacilities } = this.state;
    const pickStartDate = start_date || new Date();
    const pickEndDate = end_date || new Date();
    const days = ['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob']
    const months = [
      "Styczeń",
      "Luty",
      "Marzec",
      "Kwiecień",
      "Maj",
      "Czerwiec",
      "Lipiec",
      "Sierpień",
      "Wrzesień",
      "Październik",
      "Listopad",
      "Grudzień",
    ]
    const locale = {
      localize: {
        day: n => days[n],
        month: n => months[n]
      },
      formatLong: {
        date: () => 'yyyy-mm-dd'
      }
    }

    if (redirect) {
      return <Navigate to={redirect} />
    }

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { profileError.response ? profileError.response.data.message : profileError }
      </div>
    }

    return (
      <div className="container">
        {(userReady) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="card">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis" href="/admin">
                          <img src={homeIcon} alt="Admin Home" className="admin-home-icon" />
                          <span className="visually-hidden">Home</span>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis fw-semibold text-decoration-none" href="/admin">Admin</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        <Link to={{pathname: '/admin/trainings'}} target="_self">
                          Zjazdy
                        </Link>  
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Nowy zjazd
                      </li>
                    </ol>
                  </nav>
                <div className="card-body pb-0">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="text-center">
                        <div className="edit-profile"></div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ms-3">
                        <div>
                          <h4 className="card-title mb-2 mb-2-profile">Nowy zjazd</h4>
                        </div>
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div>
                              <Form
                                onSubmit={this.handleTrainingCreate}
                                ref={c => {
                                  this.form = c;
                                }}
                              >
                                {!this.state.successful && (
                                  <div>
                                    <div className="form-group">
                                      <label htmlFor="startDate">Data rozpoczęcia zjazdu</label>
                                      <DatePicker
                                        showToday
                                        name="startDate"
                                        dateFormat="yyyy-MM-dd"
                                        locale={locale}
                                        selected={pickStartDate}
                                        onChange={this.onChangeStartDate}
                                        validations={[startDate]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="endDate">Data zakończenia zjazdu</label>
                                      <DatePicker
                                        showToday
                                        name="endDate"
                                        dateFormat="yyyy-MM-dd"
                                        locale={locale}
                                        selected={pickEndDate}
                                        onChange={this.onChangeEndDate}
                                        validations={[endDate]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="section">Sekcja</label>
                                      <ReactSelect 
                                        className="basic-multi-select"
                                        name="section"
                                        onChange={this.onChangeSection} 
                                        options={allSections} 
                                        getOptionLabel={(allSections) => allSections.attributes.name}
                                        getOptionValue={(allSections) => allSections.id}
                                        required={true}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="training_test">Test</label>
                                      <ReactSelect 
                                        className="basic-multi-select"
                                        name="training_test"
                                        onChange={this.onChangeVerification} 
                                        options={allVerifications} 
                                        getOptionLabel={(allVerifications) => allVerifications.attributes.name}
                                        getOptionValue={(allVerifications) => allVerifications.id}
                                        required={false}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="training_additional_info">Obiekt</label>
                                      <ReactSelect 
                                        className="basic-multi-select"
                                        name="training_additional_info"
                                        onChange={this.onChangeFacility} 
                                        options={allFacilities} 
                                        getOptionLabel={(allFacilities) => allFacilities.attributes.name}
                                        getOptionValue={(allFacilities) => allFacilities.id}
                                        required={true}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="training_facility">Dodatkowe informacje</label>
                                      <textarea
                                        name="training_facility"
                                        onChange={this.onChangeAdditionalInfo}
                                        rows={4}
                                        cols={40}
                                        required={true}
                                      />
                                    </div>
                                    <p />
                                    {
                                      this.state.link ? <iframe src={`https://www.google.com/maps/`+this.state.link} title="Map" width="600" height="450" style={{border:0}} allowfullscreen="" loading="async" referrerpolicy="no-referrer-when-downgrade" /> : ''
                                    }
                                    <p />

                                    {
                                      <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                        {this.state.loading && (
                                          <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Zapisz</span>
                                      </button>
                                    }
                                  </div>
                                )}
                                {(this.state.successful || this.state.error) && (
                                  <div className="form-group">
                                    <div
                                      className={
                                        this.state.error
                                          ? "alert alert-danger"
                                          : "alert alert-success"
                                      }
                                      role="alert"
                                    >
                                      {this.state.message}
                                    </div>
                                  </div>
                                )}
                                <CheckButton
                                  style={{ display: "none" }}
                                  ref={c => {
                                    this.checkBtn = c;
                                  }}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
          : null}
      </div>
    )
  }
}