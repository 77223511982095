import axios from "axios";
import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Pagination from '../components/pagination';
import '../custom_css/admin_users.scss';
import '../custom_css/pagination.scss';
import homeIcon from "../custom_img/home_icon.png";
import tableEditRow from "../custom_img/table_edit_row.png";
import tableSortDown from "../custom_img/table_sort_down.png";
import tableSortUp from "../custom_img/table_sort_up.png";

const API_URL = "https://poborowy.pl/";
const PAGE_LIMIT = 30;

export default class AdminTrainingRegistrations extends Component {
  constructor(props) {
    super(props);

    this.onChangeSortAttribute = this.onChangeSortAttribute.bind(this);
    this.onChangeTableSearch = this.onChangeTableSearch.bind(this);

    this.state = {
      redirect: null,
      currentUser: null,
      allRegistrationForms: [],
      currentRegistrationForm: [],
      profileError: null,
      currentPage: null,
      totalPages: null,
      sortAttribute: null,
      sortDirection: 'desc',
      totalCount: null,
      userReady: null,
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  presenceClassification(type) {
    switch (type) {
      case 'yes':
        return 'obecny';
      case 'no':
        return 'nieobecny';
      case 'other_time':
        return 'inna grupa';
      default:
        return 'nieobecny';
    }
  }

  hotelClassification(type) {
    switch (type) {
      case 'room1':
        return '1 osobowy';
      case 'room2':
        return '2 osobowy';
      case 'no_room':
        return 'bez noclegu';
      default:
        return 'bez noclegu';
    }
  }

  mealClassification(type) {
    switch (type) {
      case 'meal_weekend':
        return 'weekend';
      case 'meal_saturday':
        return 'sobota';
      case 'meal_sunday':
        return 'niedziela';
      case 'no_meal':
        return 'bez posiłku';
      default:
        return 'bez posiłku';
    }
  }

  onChangeSortAttribute(data) {
    this.onPageChanged({
      currentPage: data.currentPage,
      totalPages: data.totalPages,
      pageLimit: PAGE_LIMIT,
      totalRecords: data.totalRecords,
      sortAttribute: data.sortAttribute,
      sortDirection: data.sortDirection
    });
  }

  onChangeTableSearch(e) {
    var input, filter, table, tr, i;
    input = document.getElementById("tableSearchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("usersTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      const tableData = tr[i].getElementsByTagName("td");
      let allTextContent = '';
      for (let ind = 0; ind < tableData.length; ind++) {
          allTextContent += tableData[ind].innerText;
      }

      if (allTextContent) {
        if (allTextContent.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  componentDidMount() {
    axios.get(API_URL + "user/information/" + this.state.currentUserId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.token,
        'Email': this.state.currentUserEmail
      },
      })
      .then(response => {
        this.setState({
          error: false,
          message: ""
        });
        const currentUser = response.data.users.data.attributes;
        if (currentUser.id_role === 'regular') {
          const redirect = '/profile'
          this.setState({ redirect });
        } else {
          const userReady = true;
          this.setState({ currentUser, userReady });
          // GET ALL TRAINING REGISTRATION FORMS
          axios.get(API_URL + "trainingforms", {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': this.state.token,
              'Email': this.state.currentUserEmail
            },
          })
          .then(response => {
            const { data: allRegistrationForms = [] } = response.data.training_registrations;
            const totalCount = response.data.recordsTotal;
            this.setState({ allRegistrationForms, totalCount });
          })
          .catch(err => {
            if (err.response.status === 401) {
              const redirect = '/login'
              this.setState({ redirect });
            } else {
              const profileError = err;
              this.setState({ profileError });
            }
          })
        }
      })
      .catch(err => {
        if (err.response.status === 401) {
            const redirect = '/login'
            this.setState({ redirect });
        } else {
            const profileError = err;
            this.setState({ profileError });
        }
      })
  }

  onPageChanged = data => {
    const { currentPage, pageLimit, sortAttribute, sortDirection } = data;

    if (sortDirection) {
      this.setState({ sortDirection, sortAttribute });
    }

    if (this.state.token) {
      axios.get(API_URL + `trainingforms?page=${currentPage}&per_page=${pageLimit}&sort_by=${sortAttribute || 'id'}&sort_order=${sortDirection || this.state.sortDirection || 'asc'}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      })
      .then(response => {
        const { data: allRegistrationForms = [] } = response.data.training_registrations;
        const { currentPage, totalPages } = data;
        const { data: currentRegistrationForm = [] } = response.data.training_registrations;
        this.setState({ currentPage, currentRegistrationForm, totalPages, allRegistrationForms });
      })
      .catch(error => {
        console.log(error);
      })
    }
  }

  render() {
    const { currentRegistrationForm, allRegistrationForms, totalCount, profileError, redirect, currentPage, totalPages, sortAttribute, sortDirection, userReady } = this.state;
    const totalRegistrationForms = totalCount || allRegistrationForms.length;

    if (redirect) {
      return <Navigate to={redirect} />
    }

    if (totalRegistrationForms === 0) return null;
    const headerClass = ['text-dark py-2 pr-4 m-0', currentPage ? 'border-gray border-right' : ''].join(' ').trim();

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { profileError.response.data.message }
      </div>
    }

    let oldSortAttribute = sortAttribute

    const sortClick = (newSortAttribute) => {
      this.onChangeSortAttribute({
        currentPage: currentPage,
        totalPages: totalPages,
        totalRecords: totalRegistrationForms,
        sortAttribute: newSortAttribute,
        sortDirection: oldSortAttribute !== newSortAttribute && sortDirection === 'desc' ? 'asc' : sortDirection === 'asc' ? 'desc' : 'asc'
      });
    }

    return (
      <div className="container">
        {(userReady) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-xl-8-table col-xl-8-profile">
              <div className="card">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                    <li className="breadcrumb-item">
                      <a className="link-body-emphasis" href="/admin">
                        <img src={homeIcon} alt="Admin Home" className="admin-home-icon" />
                        <span className="visually-hidden">Home</span>
                      </a>
                    </li>
                    <li className="breadcrumb-item">
                      <a className="link-body-emphasis fw-semibold text-decoration-none" href="/admin">Admin</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Formularze rejestracyjne zjazdów
                    </li>
                  </ol>
                </nav>
                <div>
                  <div className="d-flex nav-profile flex-wrap align-items-center justify-content-center justify-content-lg-start">
                    <div className="admin-users-name">LISTA FORMULARZY REJESTRACYJNYCH ZJAZDÓW</div>
                    <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                    </ul>

                    <Form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
                      <Input type="search" id="tableSearchInput" ref="PwdInput" onChange={this.onChangeTableSearch} className="form-control" placeholder="Szukaj..." aria-label="Search" />
                    </Form>
                  </div>
                </div>
                <hr></hr>
                <div className="table-responsive">
                <table className="table table-striped" id="usersTable">
                  <thead>
                    <tr>
                      <th>ID <img src={sortAttribute === 'id' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie ID" className="admin-users-sort-icon" onClick={() => sortClick('id')} /></th>
                      <th>Obecność <img src={sortAttribute === 'presence' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sorowanie Obecność" className="admin-users-sort-icon" onClick={() => sortClick('presence')} /></th>
                      <th>9mm <img src={sortAttribute === 'ammo9mm' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie 9mm" className="admin-users-sort-icon" onClick={() => sortClick('ammo9mm')} /></th>
                      <th>5.56mm <img src={sortAttribute === 'ammo556mm' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie 5.56mm" className="admin-users-sort-icon" onClick={() => sortClick('ammo556mm')} /></th>
                      <th>7.62mm<img src={sortAttribute === 'ammo762mm' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie 7.62mm" className="admin-users-sort-icon" onClick={() => sortClick('ammo762mm')} /></th>
                      <th>Cena amunicji <img src={sortAttribute === 'ammo_price' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie Cena Amunicji" className="admin-users-sort-icon" onClick={() => sortClick('ammo_price')} /></th>
                      <th>Nocleg <img src={sortAttribute === 'hotel' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie Nocleg" className="admin-users-sort-icon" onClick={() => sortClick('hotel')} /></th>
                      <th>Posiłek <img src={sortAttribute === 'meal' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie Posiłek" className="admin-users-sort-icon" onClick={() => sortClick('meal')} /></th>
                      <th>Wysłany? <img src={sortAttribute === 'is_sent' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie Wysłany" className="admin-users-sort-icon" onClick={() => sortClick('is_sent')} /></th>
                      <th>ID Zjazdu <img src={sortAttribute === 'training_id' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie ID Zjazdu" className="admin-users-sort-icon" onClick={() => sortClick('training_id')} /></th>
                      <th>ID Użytkownika <img src={sortAttribute === 'user_id' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie ID Użytkownika" className="admin-users-sort-icon" onClick={() => sortClick('user_id')} /></th>
                      <th>Opcje</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentRegistrationForm.map((registration_from, i) => {
                      return(
                        <tr key={i}>
                          <td className="admin-users-cell">{registration_from.id}</td>
                          <td className="admin-users-cell">{this.presenceClassification(registration_from.attributes.presence)}</td>
                          <td className="admin-users-cell">{registration_from.attributes.ammo9mm}</td>
                          <td className="admin-users-cell">{registration_from.attributes.ammo556mm}</td>
                          <td className="admin-users-cell">{registration_from.attributes.ammo762mm}</td>
                          <td className="admin-users-cell">{registration_from.attributes.ammo_price} pln</td>
                          <td className="admin-users-cell">{this.hotelClassification(registration_from.attributes.hotel)}</td>
                          <td className="admin-users-cell">{this.mealClassification(registration_from.attributes.meal)}</td>
                          <td className="admin-users-cell">{registration_from.attributes.is_sent ? 'tak' : 'nie'}</td>
                          <td className="admin-users-cell"><a href={'/admin/trainings/'+registration_from.attributes.training_id}>{registration_from.attributes.training_id}</a></td>
                          <td className="admin-users-cell"><a href={'/admin/users/'+registration_from.attributes.user_id}>{registration_from.attributes.user_id}</a></td>
                          <td className="admin-users-cell">
                            <Link to={{pathname: '/admin/training-registration-edit/' + registration_from.id}} target="_self">
                              <img src={tableEditRow} alt="Edit Registration Form" className="admin-edit-row-icon" />
                            </Link>
                          </td>
                        </tr>
                      )
                      })}
                  </tbody>
                </table>

                <div className="container mb-5 pagination-custom">
                  <div className="row d-flex flex-row py-5">
                    <div className="w-100 px-4 py-5 d-flex flex-row flex-wrap align-items-center justify-content-between">
                      <div className="d-flex flex-row align-items-center">
                        <h2 className={headerClass}>
                          <strong className="text-secondary">{totalRegistrationForms}</strong> {totalRegistrationForms ? 'łącznie' : 'brak danych'}
                        </h2>
                        { currentPage && (
                          <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                            Strona <span className="font-weight-bold">{ currentPage }</span> / <span className="font-weight-bold">{ totalPages }</span>
                          </span>
                        ) }
                      </div>
                      <div className="d-flex flex-row py-4 align-items-center">
                        <Pagination totalRecords={totalRegistrationForms} pageLimit={PAGE_LIMIT} pageNeighbours={1} onPageChanged={this.onPageChanged} />
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
          : null}
      </div>
    );
  }
}