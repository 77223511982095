import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import axios from "axios";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Swal from 'sweetalert2'
import '../custom_css/profile.scss';

const API_URL = "https://poborowy.pl/";

const vlPresence = value => {
  if (value === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Określ swoją obecność na zjeździe.
      </div>
    );
  }
};

const vlAmmoPrice = value => {
  if (value && value.length < 0) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną ilość amunicji.
      </div>
    );
  }
};

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.handleFormCreate = this.handleFormCreate.bind(this);
    this.onChangePresence = this.onChangePresence.bind(this);
    this.onChange9mm = this.onChange9mm.bind(this);
    this.onChange556mm = this.onChange556mm.bind(this);
    this.onChange762mm = this.onChange762mm.bind(this);
    this.onChangeHotel = this.onChangeHotel.bind(this);
    this.onChangeMeal = this.onChangeMeal.bind(this);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: null,
      profileError: null,
      allTrainings: [],
      allEquipments: [],
      totalTrainingsCount: 0,
      presence: null,
      ammo9mm: 0,
      ammo556mm: 0,
      ammo762mm: 0,
      hotel: null,
      meal: null,
      trainingRegistration: null,
      trainingRegistrationId: null,
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  onChangePresence(e) {
    this.setState({
      presence: e.target.value
    });
  }

  onChange9mm(e) {
    this.setState({
      ammo9mm: e.target.value
    });
  }

  onChange556mm(e) {
    this.setState({
      ammo556mm: e.target.value
    });
  }

  onChange762mm(e) {
    this.setState({
      ammo762mm: e.target.value
    });
  }

  onChangeHotel(e) {
    this.setState({
      hotel: e.target.value
    });
  }

  onChangeMeal(e) {
    this.setState({
      meal: e.target.value
    });
  }
  
  handleFormCreate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    var data = {
      presence: this.state.presence,
      ammo9mm: parseFloat(this.state.ammo9mm),
      ammo556mm: parseFloat(this.state.ammo556mm),
      ammo762mm: parseFloat(this.state.ammo762mm),
      ammo_price: parseFloat(e.target[4].value),
      hotel: this.state.hotel,
      meal: this.state.meal,
      training_id: parseInt(this.state.allTrainings[0]?.id),
      user_id: parseInt(this.state.currentUserId),
      is_sent: true
    }

    if (this.checkBtn.context._errors.length === 0) {
      this.setState({loading: true});
      axios.put(API_URL + "trainingform/" + this.state.trainingRegistrationId, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      }).then(
        axios_res => {
          if (parseInt(this.state.currentUserId) !== parseInt(axios_res.data.training_registration.data.attributes.user_id)) {
            this.setState({
              message: this.handleError(axios_res),
              successful: false,
              error: true,
              loading: false
            });
          } else {
            this.setState({
              loading: false,
              redirect: '/'
            });
            Swal.fire({
              icon: "success",
              title: "Formularz wysłany !",
              text: "Czekamy na Ciebie, do zobaczenia .. "
            });
          }
        },
        error => {
          const resMessage = error.response.data.details || error.response.data.message || error.toString();
          this.setState({
            successful: false,
            message: resMessage,
            error: true,
            loading: false
          });
        }
      );
    }
  }

  componentDidMount() {
    if (this.props.currentUser && this.props.currentUser.length > 0) {
      const currentUser = this.props.currentUser
      this.setState({ currentUser });
    } else {
      axios.get(API_URL + "user/information/" + this.state.currentUserId, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(response => {
          this.setState({ error: false, message: "" });
          const currentUser = response.data.users.data.attributes;
          const userReady = true;
        if (currentUser.image === 'default.jpg') {
          this.setState({
            error: true,
            message: 'Zaktualizuj swoje zdjęcie profilowe !'
          });
        }
        if (currentUser.id_role === 'admin' || currentUser.id_role === 'instructor') {
          const redirect = '/admin'
          this.setState({ redirect });
        }
        this.setState({ currentUser, userReady });
        // GET LAST TRAINING DATA FOR USER SECTION
        axios.get(API_URL + "test/active_information", {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(response => {
          const { data: allTrainings = [] } = response.data.trainings;
          const totalTrainingsCount = response.data.recordsTotal;
          this.setState({ totalTrainingsCount, allTrainings });
          if (this.state.currentUser.id_role === 'regular') {
            // GET TRAINING REGISTRATION FORM DATA
            axios.get(API_URL + "user_training_form?user_id="+this.state.currentUserId+"&training_id="+allTrainings[0].attributes.id, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token,
                'Email': this.state.currentUserEmail
              },
            })
            .then(response => {
              const trainingRegistration = response.data.training_registration.data.attributes.is_sent;
              const trainingRegistrationId = response.data.training_registration.data.id;
              const totalCount = response.data.recordsTotal;
              this.setState({ trainingRegistration, trainingRegistrationId, currentUser, userReady, totalCount });
            })
            .catch(err => {
              if (err.response.status === 401) {
                const redirect = '/login'
                this.setState({ redirect });
              } else {
                const profileError = err;
                this.setState({ profileError });
              }
            })
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            const redirect = '/login'
            this.setState({ redirect });
          } else {
            const profileError = err;
            this.setState({ profileError });
          }
        })
        // GET AVAILABLE EQUIPMENT DATA
        axios.get(API_URL + "equipment/information", {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(response => {
          const { data: allEquipments = [] } = response.data.equipment;
          this.setState({ allEquipments });
        })
        .catch(err => {
          if (err.response.status === 401) {
            const redirect = '/login'
            this.setState({ redirect });
          } else {
            const profileError = err;
            this.setState({ profileError });
          }
        })
      })
      .catch(err => {
        if (err.response.status === 401) {
          const redirect = '/login'
          this.setState({ redirect });
        } else {
          const profileError = err;
          this.setState({ profileError });
        }
      })
    }
  }

  handleError(err) {
    if (!err.hasOwnProperty('response')) {
      err.response = {
          data: {
            details: "Wystąpił nieoczekiwany błąd."
          }
       }
    }
    if (Object.keys(err.response.data.details).length !== 0) {
      var response_error = JSON.stringify(err.response.data.details);
      if (response_error) {
        response_error = response_error.slice(1, response_error.length-1).replace(/"/g, "")
        response_error = response_error.split(',')
        response_error = response_error.join(".  ");
        return response_error
      }
    } else {
      return err.response.data.message
    }
  }

  render() {
    const { userReady, currentUser, profileError, redirect, totalTrainingsCount, allTrainings, allEquipments, trainingRegistration } = this.state;

    var ammunition = allEquipments.filter(obj => {
      return obj.attributes.id_type === 'ammunition'
    })

    var price9mm = ammunition.find((element) => element.attributes.caliber === '9 mm')?.attributes.base_price;
    var price556mm = ammunition.find((element) => element.attributes.caliber === '5.56 mm')?.attributes.base_price;
    var price762mm = ammunition.find((element) => element.attributes.caliber === '7.62 mm')?.attributes.base_price;
    var ammo_price = (price9mm ? price9mm * this.state.ammo9mm : 0) + (price556mm ? price556mm  * this.state.ammo556mm : 0) + (price762mm ? price762mm * this.state.ammo762mm : 0)

    var ammoTable = [];

    for (var i = 0; i < ammunition.length; i++) {
      ammoTable.push(
        <tr key={ammunition[i].id}>
          <td className="table-color-text">{ ammunition[i].attributes.name }</td>
          <td className="table-color-text">Amunicja</td>
          <td className="table-color-text">{ ammunition[i].attributes.caliber.replace(/ /g,'').replace('.', '') }</td>
          <td className="table-color-text">{ ammunition[i].attributes.base_price } pln</td>
        </tr>
      )
    }

    if (redirect) {
      return <Navigate to={redirect} />
    }

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { profileError.response.data.message }
      </div>
    }

    return (
      <div className="container">
        {(userReady) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-xl-8-profile">
              <div className="card">
                <div className="card-body pb-0 profile-background">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="text-center">
                        <img src={`images/${currentUser.image}`} className="img-fluid avatar-xxl rounded-circle" alt="" />
                        <div className="edit-profile">
                          <Link to={{pathname: '/user_edit'}} target="_self">
                            edytuj profil
                          </Link>
                        </div>
                        <h4 className="text-primary font-size-20 mt-3 mb-2">{ currentUser.name }</h4>
                      </div>
                    </div>
                    <div className="col-md-9">
                    <hr className="profile-line"></hr>
                      <div className="ms-3">
                        <div>
                          <h4 className="card-title mb-2 mb-2-profile">{ currentUser.surname }</h4>
                          <h5 className="text-muted font-size-13 mb-0 mb-2-profile">
                            Sekcja: { currentUser.section_id === null ? <small className="d-inline-flex px-2 py-1 fw-semibold text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 me-2">oczekuje na nadanie</small> : currentUser.section_id }
                          </h5>
                          <p className="mb-0 text-muted"><b>Jesteś już z nami:</b> { currentUser.loyalty <= 0 ? 'kilka godzin' : currentUser.loyalty + ' dzień !' }</p>
                        </div>
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div>
                              <div className="row">
                                <div className="col-md-4 col-md-4-profile themed-grid-col">Email:</div>
                                <div className="col-md-6 col-md-6-profile themed-grid-col">{ currentUser.email }</div>
                              </div>
                              <div className="row">
                                <div className="col-md-4 col-md-4-profile themed-grid-col">Telefon:</div>
                                <div className="col-md-6 col-md-6-profile themed-grid-col">{ currentUser.phone }</div>
                              </div>
                              <div className="row">
                                <div className="col-md-4 col-md-4-profile themed-grid-col">Rola:</div>
                                <div className="col-md-6 col-md-6-profile themed-grid-col">{ currentUser.id_role }</div>
                              </div>
                              <div className="row">
                                <div className="col-md-4 col-md-4-profile themed-grid-col">Leworęczny:</div>
                                <div className="col-md-6 col-md-6-profile themed-grid-col">{ currentUser.lefty ? 'Tak' : 'Nie' }</div>
                              </div>
                              <div className="row">
                                <div className="col-md-4 col-md-4-profile themed-grid-col">Rozmiar:</div>
                                <div className="col-md-6 col-md-6-profile themed-grid-col">{ currentUser.size }</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(this.state.successful || this.state.error) && (
                    <div className="form-group">
                      <div
                        className={
                          this.state.error
                            ? "alert alert-danger"
                            : "alert alert-success"
                        }
                        role="alert"
                      >
                        {this.state.message}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  {(totalTrainingsCount > 0 && trainingRegistration !== true) ?
                    <div>
                      <h2>GRUPA {allTrainings[0].attributes.section_name.substring(1)} od {allTrainings[0].attributes.date_from} do {allTrainings[0].attributes.date_to}</h2>
                      { (allTrainings[0].attributes.additional_info) ? 
                        <div>
                          <div className="additional-info">DODATKOWE INFORMACJE:</div>
                          <p />
                          <div className="additional-info-body" dangerouslySetInnerHTML={{__html: allTrainings[0].attributes.additional_info}} />
                          <br />
                          <div><b>link do płatności</b>: <a href='https://szkoleniagrom.pl/produkty/oplata-za-sekcje' target='_blank' rel='noreferrer'>https://szkoleniagrom.pl/produkty/oplata-za-sekcje/</a></div>
                          <br />
                          
                        </div>
                        : <div className="additional-info">BRAK DODATKOWYCH INFORMACJI</div>
                      }
                      <div>
                        <Form
                          onSubmit={this.handleFormCreate}
                          ref={c => {
                            this.form = c;
                          }}
                        >
                        {!this.state.successful && (
                        <div>
                          <div className="form-group">
                              <label className="form-label-bold" htmlFor="">OBECNOŚĆ</label>
                              <select name="presence" id="presence" className="form-control select-size-first" onChange={this.onChangePresence} value={this.state.presence || ""} required={true} validations={[vlPresence]}>
                                <option value=''>...</option>
                                <option value="yes">Będę zgodnie z harmonogramem</option>
                                <option value="other_time">Nie będzie mnie i chcę odrobić z inną grupą</option>
                                <option value="no">Nie będzie mnie i nie będę odrabiać</option>
                              </select>
                          </div>
                          <p />
                          <label className="form-label-bold" htmlFor="">AMUNICJA</label>
                          <div>Lista zawiera dostępną amunicje wraz z ceną za sztukę.<br />Wpisz w poniższych oknach ile amunicji potrzebujesz w wybranym kalibrze.<br />Rozliczenie amunicji gotówką w trakcie zjazdu.</div>
                          <p />
                          <table className="table table-striped table-hover table-bordered">
                            <thead>
                              <tr>
                                <th className="table-header-text" scope="col">Nazwa</th>
                                <th className="table-header-text" scope="col">Typ</th>
                                <th className="table-header-text" scope="col">Kaliber</th>
                                <th className="table-header-text" scope="col">Cena</th>
                              </tr>
                            </thead>
                            <tbody>
                              { ammoTable }
                            </tbody>
                          </table>
                          <p />
                          <div className="form-group">
                            <label htmlFor="9mm">9 mm</label>
                            <Input
                              type="number"
                              step="1"
                              className="form-control form-field-width-short"
                              name="9mm"
                              value={this.state.ammo9mm}
                              onChange={this.onChange9mm}
                              validations={[vlAmmoPrice]}
                              disabled={price9mm === undefined ? true : false}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="556mm">5.56 mm</label>
                            <Input
                              type="number"
                              step="1"
                              className="form-control form-field-width-short"
                              name="556mm"
                              value={this.state.ammo556mm}
                              onChange={this.onChange556mm}
                              validations={[vlAmmoPrice]}
                              disabled={price556mm === undefined ? true : false}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="762mm">7.62 mm</label>
                            <Input
                              type="number"
                              step="1"
                              className="form-control form-field-width-short"
                              name="762mm"
                              value={this.state.ammo762mm}
                              onChange={this.onChange762mm}
                              validations={[vlAmmoPrice]}
                              disabled={price762mm === undefined ? true : false}
                            />
                          </div>
                          <p />
                            Łącznie amunicja: <b>{ ammo_price.toFixed(2) }</b> pln
                            <div className="form-group">
                            <Input 
                              type="hidden"
                              name="ammo-price"
                              id="ammo-price"
                              value={ammo_price.toFixed(2)}
                            />
                            </div>
                          <p />
                            <label className="form-label-bold" htmlFor="">NOCLEG</label>
                            <div className="form-group">
                              <select name="hotel" id="hotel" className="form-control select-size-first" onChange={this.onChangeHotel} value={this.state.hotel || ""} required={true} validations={[vlPresence]}>
                                <option value=''>...</option>
                                <option value="room1">Pokój 1 osobowy (130 pln)</option>
                                <option value="room2">Pokój 2 osobowy (190 pln)</option>
                                <option value="no_room">Bez noclegu</option>
                              </select>
                            </div>
                          <p />
                            <label className="form-label-bold" htmlFor="">OBIAD</label>
                            <div className="form-group">
                              <select name="meal" id="meal" className="form-control select-size-first" onChange={this.onChangeMeal} value={this.state.meal || ""} required={true} validations={[vlPresence]}>
                                <option value=''>...</option>
                                <option value="meal_weekend">Sobota i Niedziela (60 pln)</option>
                                <option value="meal_saturday">Tylko Sobota (30 pln)</option>
                                <option value="meal_sunday">Tylko Niedziela (30 pln)</option>
                                <option value="no_meal">Bez obiadu</option>
                              </select>
                            </div>
                          <p />
                          {
                            <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              <span>Wyślij</span>
                            </button>
                          }
                          </div>
                          )}
                          <CheckButton
                            style={{ display: "none" }}
                            ref={c => {
                              this.checkBtn = c;
                            }}
                          />
                        </Form>  
                      </div>
                      <hr />
                    </div>
                    : 
                    null
                  }
                  <div>
                    <h4 className="card-title mb-4">Twoje szkolenia bojowe</h4>
                    <ul className="list-unstyled work-activity mb-0">
                      <li className="work-item" data-date="2024-01-20">
                        <h6 className="lh-base mb-0 mb-0-profile">Prowadzący: Piachu</h6>
                        <p className="font-size-13 mb-3">Taktyka czarna</p>
                        <p>Jakiś opis przedstawiający zakres szkolenia oraz ilość rasistkowskich kawałów.</p>
                      </li>
                      <li className="work-item" data-date="2023-12-01">
                        <h6 className="lh-base mb-0 mb-0-profile">Prowadzący: Dajmos</h6>
                        <p className="font-size-13 mb-3">Taktyka zielona</p>
                        <p className="mb-0">Jakiś opis przedstawiający zakres szkolenia oraz ilość rasistkowskich kawałów.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
          : null}
      </div>
    );
  }
}