import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import axios from "axios";
import Swal from 'sweetalert2'
import homeIcon from "../custom_img/home_icon.png";
import '../custom_css/profile_edit.scss';

const API_URL = "https://poborowy.pl/";

export default class AdminTrainingRegistrationEdit extends Component {
  constructor(props) {
    super(props);
    this.handleTrainingRegistrationEdit = this.handleTrainingRegistrationEdit.bind(this);
    this.onChangePresence = this.onChangePresence.bind(this);
    this.onChange9mm = this.onChange9mm.bind(this);
    this.onChange556mm = this.onChange556mm.bind(this);
    this.onChange762mm = this.onChange762mm.bind(this);
    this.onChangeAmmoPrice = this.onChangeAmmoPrice.bind(this);
    this.onChangeHotel = this.onChangeHotel.bind(this);
    this.onChangeMeal = this.onChangeMeal.bind(this);
    this.onChangeIsSent = this.onChangeIsSent.bind(this);
    this.onChangeTrainingId = this.onChangeTrainingId.bind(this);

    this.state = {
      presence: null,
      ammo9mm: null,
      ammo556mm: null,
      ammo762mm: null,
      ammo_price: null,
      hotel: null,
      meal: null,
      is_sent: null,
      training_id: null,
      user_id: null,
      allEquipments: [],
      url_id: window.location.pathname.split('/')[3],
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  onChangePresence(e) {
    this.setState({
      presence: e.target.value
    });
  }

  onChange9mm(e) {
    this.setState({
      ammo9mm: e.target.value
    });
  }

  onChange556mm(e) {
    this.setState({
      ammo556mm: e.target.value
    });
  }

  onChange762mm(e) {
    this.setState({
      ammo762mm: e.target.value
    });
  }

  onChangeAmmoPrice(e) {
    this.setState({
      ammo_price: e.target.value
    });
  }

  onChangeHotel(e) {
    this.setState({
      hotel: e.target.value
    });
  }

  onChangeMeal(e) {
    this.setState({
      meal: e.target.value
    });
  }

  onChangeIsSent(e) {
    this.setState({
      is_sent: e.target.value
    });
  }

  onChangeTrainingId(e) {
    this.setState({
      training_id: e.target.value
    });
  }

  handleTrainingRegistrationEdit(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
      loading: true
    });

    this.form.validateAll();

    var data = {
      presence: this.state.presence,
      ammo9mm: parseInt(this.state.ammo9mm),
      ammo556mm: parseInt(this.state.ammo556mm),
      ammo762mm: parseInt(this.state.ammo762mm),
      ammo_price: parseFloat(e.target[4].value),
      hotel: this.state.hotel,
      meal: this.state.meal,
      is_sent: this.state.is_sent,
      training_id: parseInt(this.state.training_id),
      user_id: parseInt(this.state.user_id)
    }

    if (this.checkBtn.context._errors.length === 0) {
      axios.put(API_URL + "trainingform/" + this.state.url_id, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      }).then(
        axios_res => {
          if (this.state.training_id !== axios_res.data.training_registration.data.attributes.training_id) {
            this.setState({
              message: this.handleError(axios_res),
              successful: false,
              error: true,
              loading: false
            });
          } else {
            this.setState({
              loading: false,
              redirect: '/admin/training-registration-forms'
            });
            Swal.fire({
              icon: "success",
              title: "Zaktualizowano !",
              text: "Formularz został poprawnie zaktualizowany"
            });
          }
        },
        error => {
          const resMessage = error.response.data.details || error.response.data.message || error.toString();

          this.setState({
            successful: false,
            message: resMessage,
            error: true,
            loading: false
          });
        }
      );
    }
  }

  componentDidMount() {
    // GET USER DATA
    axios.get(API_URL + "user/information/" + this.state.currentUserId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.token,
        'Email': this.state.currentUserEmail
      },
    })
    .then(response => {
      this.setState({error: false});
      const currentUser = response.data.users.data.attributes;
      const userReady = true;
      if (currentUser.id_role === 'regular') {
        const redirect = '/login'
        this.setState({ redirect });
      }
      // GET AVAILABLE EQUIPMENT DATA
      axios.get(API_URL + "equipment/information", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      })
      .then(response => {
        const { data: allEquipments = [] } = response.data.equipment;
        this.setState({ allEquipments });
      })
      .catch(err => {
        if (err.response.status === 401) {
          const redirect = '/login'
          this.setState({ redirect });
        } else {
          const profileError = err;
          this.setState({ profileError });
        }
      })
      // GET TRAINING REGISTRATION DATA
      axios.get(API_URL + "trainingforms/" + this.state.url_id, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(res => {
          const currentTrainingRegistration = res.data.training_registration.data.attributes;
          const presence =  currentTrainingRegistration.presence;
          const ammo9mm = currentTrainingRegistration.ammo9mm;
          const ammo556mm = currentTrainingRegistration.ammo556mm;
          const ammo762mm = currentTrainingRegistration.ammo762mm;
          const ammo_price = currentTrainingRegistration.ammo_price;
          const hotel = currentTrainingRegistration.hotel;
          const meal = currentTrainingRegistration.meal;
          const is_sent = currentTrainingRegistration.is_sent;
          const training_id = currentTrainingRegistration.training_id;
          const user_id = currentTrainingRegistration.user_id;
          this.setState({ userReady, currentUser, currentTrainingRegistration, presence, ammo9mm, ammo556mm, ammo762mm, ammo_price, hotel, meal, is_sent, training_id, user_id });
        })
        .catch(err => {
          this.setState({
            successful: false,
            profileError: `Nie znaleziono obiektu z ID ${this.state.url_id}`,
            error: true
          });
        })
    })
    .catch(err => {
      if (err.response.status === 401) {
        const redirect = '/login'
        this.setState({ redirect });
      } else if (err.response.status === 404) {
        this.setState({
          successful: false,
          profileError: `Wystąpił błąd lub nie znaleziono użytkownika z ID ${this.state.url_id}`,
          error: true
        });
      } else {
        const profileError = err;
        this.setState({ profileError });
      }
    })
  }

  handleError(err) {
    if (!err.hasOwnProperty('response')) {
      err.response = {
          data: {
            details: "Wystąpił nieoczekiwany błąd."
          }
       }
    }
    if (Object.keys(err.response.data.details).length !== 0) {
      var response_error = JSON.stringify(err.response.data.details);
      if (response_error) {
        response_error = response_error.slice(1, response_error.length-1).replace(/"/g, "")
        response_error = response_error.split(',')
        response_error = response_error.join(".  ");
        return response_error
      }
    } else {
      return err.response.data.message
    }
  }

  render() {
    const { userReady, currentUser, profileError, redirect, allEquipments } = this.state;

    var ammunition = allEquipments.filter(obj => {
      return obj.attributes.id_type === 'ammunition'
    })

    var price9mm = ammunition.find((element) => element.attributes.caliber === '9 mm')?.attributes.base_price;
    var price556mm = ammunition.find((element) => element.attributes.caliber === '5.56 mm')?.attributes.base_price;
    var price762mm = ammunition.find((element) => element.attributes.caliber === '7.62 mm')?.attributes.base_price;
    var ammo_price = (price9mm ? price9mm * this.state.ammo9mm : 0) + (price556mm ? price556mm  * this.state.ammo556mm : 0) + (price762mm ? price762mm * this.state.ammo762mm : 0)

    if (redirect) {
      return <Navigate to={redirect} />
    }

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { profileError.response ? profileError.response.data.message : profileError }
      </div>
    }

    return (
      <div className="container">
        {(userReady) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="card">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis" href="/admin">
                          <img src={homeIcon} alt="Admin Home" className="admin-home-icon" />
                          <span className="visually-hidden">Home</span>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis fw-semibold text-decoration-none" href="/admin">Admin</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        <Link to={{pathname: '/admin/training-registration-forms'}} target="_self">
                        Formularze rejestracyjne zjazdów
                        </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Edycja Formularza
                      </li>
                    </ol>
                  </nav>
                <div className="card-body pb-0">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="text-center">
                        <div className="edit-profile"><b>FORMULARZ REJESTRACYJNY ZJAZDU</b></div>
                        <h4 className="text-primary font-size-20 mt-3 mb-2">{ currentUser.username }</h4>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ms-3">
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div>
                              <Form
                                onSubmit={this.handleTrainingRegistrationEdit}
                                ref={c => {
                                  this.form = c;
                                }}
                              >
                                {!this.state.successful && (
                                  <div>
                                    <div className="form-group">
                                      <label htmlFor="id_type">Obecność</label>
                                      <select name="presence" id="presence" className="form-control select-size-first" onChange={this.onChangePresence} value={this.state.presence || ""} required={true}>
                                        <option value="no">nieobecny</option>
                                        <option value="yes">obecny</option>
                                        <option value="other_time">inna grupa</option>
                                      </select>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="ammo9mm">Amunicja 9 mm</label>
                                      <Input
                                        type="number"
                                        step="1"
                                        className="form-control"
                                        name="ammo9mm"
                                        value={this.state.ammo9mm}
                                        onChange={this.onChange9mm}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="ammo556mm">Amunicja 5.56 mm</label>
                                      <Input
                                        type="number"
                                        step="1"
                                        className="form-control"
                                        name="ammo556mm"
                                        value={this.state.ammo556mm}
                                        onChange={this.onChange556mm}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="ammo762mm">Amunicja 7.62 mm</label>
                                      <Input
                                        type="number"
                                        step="1"
                                        className="form-control"
                                        name="ammo762mm"
                                        value={this.state.ammo762mm}
                                        onChange={this.onChange762mm}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="ammo_price">Cena Amunicji</label>
                                      <Input
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        name="ammo_price"
                                        value={ammo_price.toFixed(2)}
                                        onChange={this.onChangeAmmoPrice}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="hotel">Nocleg</label>
                                      <select name="hotel" id="hotel" className="form-control select-size-first" onChange={this.onChangeHotel} value={this.state.hotel || ""} required={true}>
                                        <option value="room1">1 osobowy</option>
                                        <option value="room2">2 osobowy</option>
                                        <option value="no_room">bez noclegu</option>
                                      </select>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="meal">Posiłek</label>
                                      <select name="meal" id="meal" className="form-control select-size-first" onChange={this.onChangeMeal} value={this.state.meal || ""} required={true}>
                                        <option value="meal_weekend">weekend</option>
                                        <option value="meal_saturday">sobota</option>
                                        <option value="meal_sunday">niedziela</option>
                                        <option value="no_meal">bez posiłku</option>
                                      </select>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="is_sent">Formularz wysłany ?</label>
                                      <select name="is_sent" id="is_sent" className="form-control select-size-first" onChange={this.onChangeIsSent} value={this.state.is_sent || ""} required={true}>
                                        <option value="true">tak</option>
                                        <option value="false">nie</option>
                                      </select>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="training_id">Identyfikator Zjazdu</label>
                                      <Input
                                        type="number"
                                        step="1"
                                        className="form-control"
                                        name="training_id"
                                        value={this.state.training_id}
                                        onChange={this.onChangeTrainingId}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="user_id">Identyfikator Kursanta</label>
                                      <Input
                                        type="number"
                                        step="1"
                                        className="form-control"
                                        name="user_id"
                                        value={this.state.user_id}
                                        disabled={true}
                                      />
                                    </div>
                                    <p />
                                    {
                                      <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                        {this.state.loading && (
                                          <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Zapisz</span>
                                      </button>
                                    }
                                  </div>
                                )}
                                {(this.state.successful || this.state.error) && (
                                  <div className="form-group">
                                    <div
                                      className={
                                        this.state.error
                                          ? "alert alert-danger"
                                          : "alert alert-success"
                                      }
                                      role="alert"
                                    >
                                      {this.state.message}
                                    </div>
                                  </div>
                                )}
                                <CheckButton
                                  style={{ display: "none" }}
                                  ref={c => {
                                    this.checkBtn = c;
                                  }}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          : null}
      </div>
    )
  }
}