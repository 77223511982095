import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import axios from "axios";
import Swal from 'sweetalert2'
import ReactSelect from 'react-select'
import DatePicker from "react-datepicker";
import homeIcon from "../custom_img/home_icon.png";
import '../custom_css/profile_edit.scss';
import 'react-datepicker/dist/react-datepicker.css';
import tableEditRow from "../custom_img/table_edit_row.png";

const API_URL = "https://poborowy.pl/";

const startDate = value => {
  if (value && value.toISOString().split('T')[0].match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/) === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną datę w postaci RRRR-MM-DD
      </div>
    );
  }
};

const endDate = value => {
  if (value && value.toISOString().split('T')[0].match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/) === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną datę w postaci RRRR-MM-DD
      </div>
    );
  }
};

export default class AdminTrainingsEdit extends Component {
  constructor(props) {
    super(props);
    this.handleTrainingEdit = this.handleTrainingEdit.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onChangeSection = this.onChangeSection.bind(this);
    this.onChangeVerification = this.onChangeVerification.bind(this);
    this.onChangeFacility = this.onChangeFacility.bind(this);

    this.state = {
      start_date: null,
      end_date: null,
      currentTraining: null,
      section_id: null,
      verification_id: null,
      facility_id: null,
      allSections: [],
      allVerifications: [],
      allFacilities: [],
      userTrainingForms: [],
      url_id: window.location.pathname.split('/')[3],
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  presenceClassification(type) {
    switch (type) {
      case 'yes':
        return 'obecny';
      case 'no':
        return 'nieobecny';
      case 'other_time':
        return 'inna grupa';
      default:
        return 'nieobecny';
    }
  }

  onChangeStartDate(e) {
    this.setState({
      start_date: e
    });
  }

  onChangeEndDate(e) {
    this.setState({
      end_date: e
    });
  }

  onChangeSection(e) {
    this.setState({
      section_id: e.id
    });
  }

  onChangeVerification(e) {
    this.setState({
      verification_id: e.id
    });
  }

  onChangeFacility(e) {
    this.setState({
      facility_id: e.id
    });
  }

  handleTrainingEdit(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    var data = {
      date_from: (typeof this.state.start_date === 'string') ? this.state.start_date : this.state.start_date.toISOString().split('T')[0],
      date_to: (typeof this.state.end_date === 'string') ? this.state.end_date : this.state.end_date.toISOString().split('T')[0],
      section_id: parseInt(this.state.section_id),
      verification_id: parseInt(this.state.verification_id),
      facility_id: parseInt(this.state.facility_id)
    }

    if (this.checkBtn.context._errors.length === 0) {
      this.setState({loading: true});
      axios.put(API_URL + "test/information/" + this.state.url_id, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      }).then(
        axios_res => {
          this.setState({
            loading: false,
            redirect: '/admin/trainings'
          });
          Swal.fire({
            icon: "success",
            title: "Zaktualizowano !",
            text: "Zjazd został poprawnie zaktualizowany"
          });
        },
        error => {
          const resMessage = error.response.data.details || error.response.data.message || error.toString();
          this.setState({
            successful: false,
            message: resMessage,
            error: true,
            loading: false
          });
        }
      );
    }
  }

  componentDidMount() {
    // GET USER DATA
    axios.get(API_URL + "user/information/" + this.state.currentUserId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.token,
        'Email': this.state.currentUserEmail
      },
    })
    .then(response => {
      this.setState({error: false});
      const currentUser = response.data.users.data.attributes;
      const userReady = true;
      if (currentUser.id_role === 'regular') {
        const redirect = '/login'
        this.setState({ redirect });
      } else {
        this.setState({ userReady });
        // GET TRAINING DATA
        axios.get(API_URL + "test/information/" + this.state.url_id, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(response => {
          const currentTraining = response.data.trainings.data.attributes;
          const start_date =  currentTraining.date_from;
          const end_date = currentTraining.date_to;
          const section_id = currentTraining.section_id;
          const verification_id = currentTraining.verification_id;
          const facility_id = currentTraining.facility_id;
          this.setState({ start_date, end_date, section_id, verification_id, facility_id });
          // GET ALL VERIFICATIONS DATA
          axios.get(API_URL + "verifications/information", {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': this.state.token,
              'Email': this.state.currentUserEmail
            },
          })
          .then(response => {
            const { data: allVerifications = [] } = response.data.verifications;
            allVerifications.unshift({'id': '', 'type': 'verification', 'attributes': {'name': '....'}});
            this.setState({ allVerifications });
            // GET TRAINING REGISTRATION FORMS
            axios.get(API_URL + "userTrainingforms?training_id=" + this.state.url_id, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.token,
                'Email': this.state.currentUserEmail
              },
            })
            .then(res => {
              const userTrainingForms = res.data.training_registration_users.data
              this.setState({userTrainingForms});
              // GET ALL FACILITIES DATA
              axios.get(API_URL + "locations/information", {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': this.state.token,
                  'Email': this.state.currentUserEmail
                },
              })
              .then(response => {
                const { data: allFacilities = [] } = response.data.facilities;
                this.setState({ allFacilities });
                // GET ALL SECTION DATA
                axios.get(API_URL + "sections/information", {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token,
                    'Email': this.state.currentUserEmail
                  },
                })
                .then(response => {
                  const { data: allSections = [] } = response.data.sections;
                  this.setState({ allSections });
                })
                .catch(err => {
                  if (err.response.status === 401) {
                    const redirect = '/login'
                    this.setState({ redirect });
                  } else {
                    const profileError = err;
                    this.setState({ profileError });
                  }
                })
              })
              .catch(err => {
                if (err.response.status === 401) {
                  const redirect = '/login'
                  this.setState({ redirect });
                } else {
                  const profileError = err;
                  this.setState({ profileError });
                }
              })
            })
            .catch(err => {
              this.setState({
                successful: false,
                profileError: `Nie mogłem pobrać formularzy rejestracyjnych zjazdu`,
                error: true
              });
            })
          })
          .catch(err => {
            if (err.response.status === 401) {
              const redirect = '/login'
              this.setState({ redirect });
            } else {
              const profileError = err;
              this.setState({ profileError });
            }
          })
        })
        .catch(err => {
          this.setState({
            successful: false,
            profileError: `Nie znaleziono zjazdu z ID ${this.state.url_id}`,
            error: true
          });
        })
      }
    })
    .catch(err => {
      if (err.response.status === 401) {
        const redirect = '/login'
        this.setState({ redirect });
      } else if (err.response.status === 404) {
        this.setState({
          successful: false,
          profileError: `Wystąpił błąd lub nie znaleziono użytkownika z ID ${this.state.url_id}`,
          error: true
        });
      } else {
        const profileError = err;
        this.setState({ profileError });
      }
    })
  }

  handleError(err) {
    if (!err.hasOwnProperty('response')) {
      err.response = {
          data: {
            details: "Wystąpił nieoczekiwany błąd."
          }
       }
    }
    if (Object.keys(err.response.data.details).length !== 0) {
      var response_error = JSON.stringify(err.response.data.details);
      if (response_error) {
        response_error = response_error.slice(1, response_error.length-1).replace(/"/g, "")
        response_error = response_error.split(',')
        response_error = response_error.join(".  ");
        return response_error
      }
    } else {
      return err.response.data.message
    }
  }

  render() {
    const { userReady, profileError, redirect, start_date, end_date, section_id, verification_id, facility_id, allSections, allVerifications, allFacilities, userTrainingForms } = this.state;
    const pickStartDate = start_date || new Date();
    const pickEndDate = end_date || new Date();
    const days = ['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob']
    const months = [
      "Styczeń",
      "Luty",
      "Marzec",
      "Kwiecień",
      "Maj",
      "Czerwiec",
      "Lipiec",
      "Sierpień",
      "Wrzesień",
      "Październik",
      "Listopad",
      "Grudzień",
    ]
    const locale = {
      localize: {
        day: n => days[n],
        month: n => months[n]
      },
      formatLong: {
        date: () => 'yyyy-mm-dd'
      }
    }

    if (redirect) {
      return <Navigate to={redirect} />
    }

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { profileError.response ? profileError.response.data.message : profileError }
      </div>
    }

    return (
      <div className="container">
        {(userReady) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="card">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis" href="/admin">
                          <img src={homeIcon} alt="Admin Home" className="admin-home-icon" />
                          <span className="visually-hidden">Home</span>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis fw-semibold text-decoration-none" href="/admin">Admin</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        <Link to={{pathname: '/admin/trainings'}} target="_self">
                          Zjazdy
                        </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Edycja zjazdu
                      </li>
                    </ol>
                  </nav>
                <div className="card-body pb-0">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="text-center">
                        <div className="edit-profile"></div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ms-3">
                        <div>
                          <h4 className="card-title mb-2 mb-2-profile">{'Edycja zjazdu sekcji ' + section_id + ' z dnia ' + start_date}</h4>
                        </div>
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div>
                              <Form
                                onSubmit={this.handleTrainingEdit}
                                ref={c => {
                                  this.form = c;
                                }}
                              >
                                {!this.state.successful && (
                                  <div>
                                    <div className="form-group">
                                      <label htmlFor="startDate">Data rozpoczęcia zjazdu</label>
                                      <DatePicker
                                        showToday
                                        name="startDate"
                                        dateFormat="yyyy-MM-dd"
                                        locale={locale}
                                        selected={pickStartDate}
                                        onChange={this.onChangeStartDate}
                                        validations={[startDate]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="endDate">Data zakończenia zjazdu</label>
                                      <DatePicker
                                        showToday
                                        name="endDate"
                                        dateFormat="yyyy-MM-dd"
                                        locale={locale}
                                        selected={pickEndDate}
                                        onChange={this.onChangeEndDate}
                                        validations={[endDate]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="section">Sekcja</label>
                                      <ReactSelect 
                                        className="basic-multi-select"
                                        name="section"
                                        onChange={this.onChangeSection} 
                                        options={allSections} 
                                        getOptionLabel={(allSections) => allSections.attributes.name}
                                        getOptionValue={(allSections) => allSections.id}
                                        required={true}
                                        value={allSections.filter(x => {return x.id === section_id.toString()})}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="test">Test</label>
                                      <ReactSelect 
                                        className="basic-multi-select"
                                        name="test"
                                        onChange={this.onChangeVerification} 
                                        options={allVerifications} 
                                        getOptionLabel={(allVerifications) => allVerifications.attributes.name}
                                        getOptionValue={(allVerifications) => allVerifications.id}
                                        required={false}
                                        value={allVerifications.filter(x => {return x.id === (verification_id === null ? verification_id : verification_id.toString())})}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="facility">Obiekt</label>
                                      <ReactSelect 
                                        className="basic-multi-select"
                                        name="facility"
                                        onChange={this.onChangeFacility} 
                                        options={allFacilities} 
                                        getOptionLabel={(allFacilities) => allFacilities.attributes.name}
                                        getOptionValue={(allFacilities) => allFacilities.id}
                                        required={true}
                                        value={allFacilities.filter(x => {return x.id === facility_id.toString()})}
                                      />
                                    </div>
                                    <p />
                                    {
                                      <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                        {this.state.loading && (
                                          <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Zapisz</span>
                                      </button>
                                    }
                                  </div>
                                )}
                                <p />
                                <h6 className="card-title mb-2 mb-2-profile">FORMULARZE REJESTRACYJNE</h6>
                                <table className="table table-striped" id="registrationForms">
                                  <thead>
                                    <tr>
                                      <th>ID</th>
                                      <th>Obecność</th>
                                      <th>Ammo</th>
                                      <th>Wysłany?</th>
                                      <th>Email</th>
                                      <th>Telefon</th>
                                      <th>UserID</th>
                                      <th>Opcje</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {userTrainingForms.map((trainingForm, i) => {
                                      return(
                                        <tr key={i}>
                                          <td className="admin-users-cell">{trainingForm.attributes.id}</td>
                                          <td className="admin-users-cell">{this.presenceClassification(trainingForm.attributes.presence)}</td>
                                          <td className="admin-users-cell">{trainingForm.attributes.ammo_price}</td>
                                          <td className="admin-users-cell">{trainingForm.attributes.is_sent ? 'tak' : 'nie'}</td>
                                          <td className="admin-users-cell">{trainingForm.attributes.user_email}</td>
                                          <td className="admin-users-cell">{trainingForm.attributes.user_phone}</td>
                                          <td className="admin-users-cell">{trainingForm.attributes.user_id}</td>
                                          <td className="admin-users-cell">
                                            <Link to={{pathname: '/admin/users/' + trainingForm.attributes.user_id}} target="_self">
                                              <img src={tableEditRow} alt="Edit User" className="admin-edit-row-icon" />
                                            </Link>
                                          </td>
                                        </tr>
                                      )
                                      })}
                                  </tbody>
                                </table>
                                {(this.state.successful || this.state.error) && (
                                  <div className="form-group">
                                    <div
                                      className={
                                        this.state.error
                                          ? "alert alert-danger"
                                          : "alert alert-success"
                                      }
                                      role="alert"
                                    >
                                      {this.state.message}
                                    </div>
                                  </div>
                                )}
                                <CheckButton
                                  style={{ display: "none" }}
                                  ref={c => {
                                    this.checkBtn = c;
                                  }}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          : null}
      </div>
    )
  }
}