import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
      <GoogleReCaptchaProvider
        reCaptchaKey='6Lf-DVkpAAAAAPjyhZBYUjQteT3kU-TbPoqnhCsw'
        container={{
          element: "",
          parameters: {
            badge: '[bottomright]',
            theme: 'dark',
          }
        }}
      >
        <App />
      </GoogleReCaptchaProvider>
  </BrowserRouter>
);
