import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import axios from "axios";
import '../custom_css/admin.scss';
import homeIcon from "../custom_img/home_icon.png";

const API_URL = "https://poborowy.pl/";

export default class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: null,
      profileError: null,
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  componentDidMount() {
    if (this.props.currentUser && this.props.currentUser.length > 0) {
      const currentUser = this.props.currentUser
      this.setState({ currentUser });
    } else {
      axios.get(API_URL + "user/information/" + this.state.currentUserId, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      })
      .then(response => {
        this.setState({
          error: false,
          message: ""
        });
        const currentUser = response.data.users.data.attributes;
        if (currentUser.id_role === 'regular') {
          const redirect = '/profile'
          this.setState({ redirect });
        } else {
          const userReady = true;
          this.setState({ currentUser, userReady });
        }
      })
      .catch(err => {
        if (err.response.status === 401) {
          const redirect = '/login'
          this.setState({ redirect });
        } else {
          const profileError = err;
          this.setState({ profileError });
        }
      })
    }
  }

  render() {
    const { userReady, currentUser, profileError, redirect } = this.state;

    if (redirect) {
      return <Navigate to={redirect} />
    }

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { profileError.response.data.message }
      </div>
    }

    return (
      <div className="container">
        {(userReady) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-xl-8-profile">
              <div className="card">
                <div className="card-body pb-0">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                          <li className="breadcrumb-item">
                            <a className="link-body-emphasis" href="/admin">
                              <img src={homeIcon} alt="Admin Home" className="admin-home-icon" />
                              <span className="visually-hidden">Home</span>
                            </a>
                          </li>
                          <li className="breadcrumb-item">
                            <a className="link-body-emphasis fw-semibold text-decoration-none" href="/admin">Admin</a>
                          </li>
                        </ol>
                      </nav>
                      <div className="text-center">
                        <img src={`images/${currentUser.image}`} className="img-fluid avatar-xxl rounded-circle" alt="" />
                        <div className="edit-profile">
                          <Link to={{pathname: '/user_edit'}} target="_self">
                            edytuj profil
                          </Link>
                        </div>
                        <h4 className="text-primary font-size-20 mt-3 mb-2">{ currentUser.name }</h4>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ms-3">
                        <div className="mb-2-profile-admin">
                          <span className="badge border border-danger-subtle text-danger-emphasis rounded-pill">{currentUser.id_role === 'admin' ? 'Administrator Serwisu' : 'Instruktor'}</span>
                        </div>
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div>
                            <div className="position-sticky">
                              <div className="list-group list-group-flush mx-3 mt-4">
                                <div className="list-group-item list-group-item-action py-2 ripple">
                                  <span>
                                    <Link to={{pathname: '/admin/users'}} target="_self" className="admin-menu-text">
                                      Kursanci
                                    </Link>
                                  </span>
                                </div>  
                                <div className="list-group-item list-group-item-action py-2 ripple">
                                  <span>
                                    <Link to={{pathname: '/admin/sections'}} target="_self" className="admin-menu-text">
                                      Sekcje
                                    </Link>
                                  </span>
                                </div>
                                <div className="list-group-item list-group-item-action py-2 ripple">
                                  <span>
                                    <Link to={{pathname: '/admin/facilities'}} target="_self" className="admin-menu-text">
                                      Obiekty
                                    </Link>
                                  </span>
                                </div> 
                                <div className="list-group-item list-group-item-action py-2 ripple">
                                  <span>
                                    <Link to={{pathname: '/admin/equipment'}} target="_self" className="admin-menu-text">
                                      Sprzęt
                                    </Link>
                                  </span>
                                </div>
                                <div className="list-group-item list-group-item-action py-2 ripple">
                                  <span>
                                    <Link to={{pathname: '/admin/drills'}} target="_self" className="admin-menu-text">
                                      Drills
                                    </Link>
                                  </span>
                                </div>
                                <div className="list-group-item list-group-item-action py-2 ripple">
                                  <span>
                                    <Link to={{pathname: '/admin/verifications'}} target="_self" className="admin-menu-text">
                                      Testy
                                    </Link>
                                  </span>
                                </div>
                                <div className="list-group-item list-group-item-action py-2 ripple">
                                  <span>
                                    <Link to={{pathname: '/admin/trainings'}} target="_self" className="admin-menu-text">
                                      Zjazdy
                                    </Link>
                                  </span>
                                </div>
                                <div className="list-group-item list-group-item-action py-2 ripple">
                                  <span>
                                    <Link to={{pathname: '/admin/training-registration-forms'}} target="_self" className="admin-menu-text">
                                      Formularze rejestracyjne zjazdów
                                    </Link>
                                  </span>
                                </div>
                                <a href="/admin" className="list-group-item list-group-item-action py-2 ripple"><span>Harmonogramy zajęć</span></a>
                                <a href="/admin" className="list-group-item list-group-item-action py-2 ripple"><span>Materiały</span></a>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(this.state.successful || this.state.error) && (
                    <div className="form-group">
                      <div
                        className={
                          this.state.error
                            ? "alert alert-danger"
                            : "alert alert-success"
                        }
                        role="alert"
                      >
                        {this.state.message}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> 
          : null}
      </div>
    );
  }
}