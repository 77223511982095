import React, { Component } from "react";
import axios from "axios";

const API_URL = "https://poborowy.pl/";

export default class AccountActivation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileError: null,
      message: ""
    };

    const queryParameters = new URLSearchParams(window.location.search)
    const user_token = queryParameters.get("registration_token")
    const user_id = queryParameters.get("id")

    axios.get(API_URL + "activate_user?registration_token=" + user_token + "&id=" + user_id, {
      headers: {
        'Content-Type': 'application/json'
      }, 
    })
    .then(res => {
      this.setState({
        message: 'Poprawnie aktywowano konto. Poczekaj na maila potwierdzającego możliwość logowania. Czas oczekiwania do 48H',
        profileError: false
      });
    })
    .catch(err => {
      this.setState({
        message: err.response.data.details,
        profileError: true
      });
    })
  }
  
  componentDidMount() {
  }

  render() {
    const { message, profileError } = this.state;

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { message }
      </div>
    } else {
      return <div className="alert alert-success" role="alert">
        { message }
      </div>
    }
  }
}