import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Swal from 'sweetalert2'
import '../custom_css/upload_image.scss';

const API_URL = "https://poborowy.pl/";

export default class UploadImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: null,
      successful: false,
      message: "",
      redirect: null,
      userReady: false,
      error: false,
      currentUser: null,
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      this.setState({
        image: e.target.files[0]
     })
    }
  };

  handleSubmit = (e) => {
    this.setState({
      error: false,
      loading: true
    })
    e.preventDefault()
    const formData = new FormData()
    for (const property in this.state) {
      formData.append(
        property, this.state[property]
      )
    }

    axios.put(API_URL + "user/information/" + this.state.currentUserId, formData, {
      headers: {
        'Authorization': this.state.token,
        'Email': this.state.currentUser.email
      },
    }).then(
      axios_res => {
        this.setState({
          loading: false,
          error: false,
          redirect: '/user_edit'
        });
        Swal.fire({
          icon: "success",
          title: "Zaktualizowano !",
          text: "Poprawnie zaktualizowano zdjęcie profilowe"
        });
      },
      error => {
        this.setState({
          loading: false,
          successful: false,
          message: this.handleError(error),
          error: true
        });
      }
    );
  }

  componentDidMount() {
    axios.get(API_URL + "user/information/" + this.state.currentUserId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.token,
        'Email': this.state.currentUserEmail
      }, 
    })
    .then(response => {
      const currentUser = response.data.users.data.attributes;
      const userReady = true;
      this.setState({ currentUser, userReady });
    })
    .catch(err => {
      if (err.response.status === 401) {
        const redirect = '/login'
        this.setState({ redirect });
      } else {
        const profileError = err;        
        this.setState({ profileError });
      }
    })
  }

  handleError(err) {
    if (!err.hasOwnProperty('response')) {
      err.response = {
          data: {
            details: "Wystąpił nieoczekiwany błąd."
          }
       }
    }
    if (Object.keys(err.response.data.details).length !== 0) {
      var response_error = JSON.stringify(err.response.data.details);
      if (response_error) {
        response_error = response_error.slice(1, response_error.length-1).replace(/"/g, "")
        response_error = response_error.split(',')
        response_error = response_error.join(".  ");
        return response_error
      }
    } else {
      return err.response.data.message
    }
  }

  render() {
    const { userReady, currentUser, redirect } = this.state;

    if (redirect) {
      return <Navigate to={redirect} />
    }

    return (
      <div className="container">
        {(userReady) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="card">
                <div className="card-body pb-0">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="text-center">
                        <img src={`images/${currentUser.image}`} className="img-fluid avatar-xxl rounded-circle" alt=""></img>
                        <h4 className="text-primary font-size-20 mt-3 mb-2">{currentUser.surname}</h4>
                        <h5 className="text-muted font-size-13 mb-0">
                          {(currentUser.id_role === 'admin') ?
                            <div className="mb-2-profile-admin">
                              <span className="badge border border-danger-subtle text-danger-emphasis rounded-pill">Administrator Serwisu</span>
                            </div> : 'Sekcja X'
                          }
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ms-3">
                        <div>
                          <h4 className="card-title mb-2 mb-2-profile">edycja zdjęcia profilowego</h4>
                        </div>
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div>
                              <div>
                                <Form onSubmit={this.handleSubmit} >
                                  <label htmlFor="file" className="sr-only upload-extension">Dozwolone typy plików: <b>PNG/JPG/JPEG</b></label>
                                  <label htmlFor="file" className="sr-only upload-extension">Maksymalny rozmiar pliku: <b>6 MB</b></label>
                                  <br />
                                  <Input 
                                    id="file"
                                    type="file"
                                    className="form-control upload-form-control"
                                    onChange={this.handleFileChange}
                                    accept="image/png, image/jpg, image/jpeg"
                                    required
                                  />
                                  <p></p>
                                  <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                    {this.state.loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Zapisz</span>
                                  </button>

                                  {(this.state.successful || this.state.error) && (
                                    <div className="form-group">
                                      <div
                                        className={
                                          this.state.error
                                            ? "alert alert-danger"
                                            : "alert alert-success upload-alert-success"
                                        }
                                        role="alert"
                                      >
                                        {this.state.message}
                                      </div>
                                    </div>
                                  )}
                                  <CheckButton
                                    style={{ display: "none" }}
                                    ref={c => {
                                      this.checkBtn = c;
                                    }}
                                  />
                                </Form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
          : null}
      </div>
    )
  }
}