import axios from "axios";

const API_URL = "https://poborowy.pl/";

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "login_user", {
        email,
        password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem("poborowy_token", JSON.stringify(response.data.token));
          localStorage.setItem("poborowy_user_id", JSON.stringify(response.data.id));
          localStorage.setItem("poborowy_email", JSON.stringify(response.data.email));
        }

        return response.data;
      })
      .catch(err => {
        return err;
      });
  }

  logout() {
    let keysToRemove = ["poborowy_user_id", "poborowy_email", "poborowy_token"];
    keysToRemove.forEach(k =>
      localStorage.removeItem(k))
  }

  register(name, surname, email, phone, password, password_confirmation) {
    return axios
      .post(API_URL + "register_account", {
        name,
        surname,
        email,
        phone,
        password,
        password_confirmation
      })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return err;
      });
  }

  getUserToken() {
    return JSON.parse(localStorage.getItem('poborowy_token'));
  }

  getUserId() {
    return JSON.parse(localStorage.getItem('poborowy_user_id'));
  }

}

const authService = new AuthService();

export default authService;