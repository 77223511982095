import axios from "axios";
import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Pagination from '../components/pagination'
import '../custom_css/admin_users.scss';
import '../custom_css/pagination.scss';
import homeIcon from "../custom_img/home_icon.png";
import tableSortDown from "../custom_img/table_sort_down.png";
import tableSortUp from "../custom_img/table_sort_up.png";
import tableEditRow from "../custom_img/table_edit_row.png";

const API_URL = "https://poborowy.pl/";
const PAGE_LIMIT = 30;

export default class AdminSections extends Component {
  constructor(props) {
    super(props);

    this.onChangeSortAttribute = this.onChangeSortAttribute.bind(this);
    this.onChangeTableSearch = this.onChangeTableSearch.bind(this);

    this.state = {
      redirect: null,
      currentUser: null,
      allSections: [],
      currentSections: [],
      profileError: null,
      currentPage: null,
      totalPages: null,
      sortAttribute: null,
      sortDirection: 'asc',
      totalCount: null,
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  onChangeSortAttribute(data) {
    this.onPageChanged({
      currentPage: data.currentPage,
      totalPages: data.totalPages,
      pageLimit: PAGE_LIMIT,
      totalRecords: data.totalRecords,
      sortAttribute: data.sortAttribute,
      sortDirection: data.sortDirection
    });
  }

  onChangeTableSearch(e) {
    var input, filter, table, tr, i;
    input = document.getElementById("tableSearchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("usersTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      const tableData = tr[i].getElementsByTagName("td");
      let allTextContent = '';
      for (let ind = 0; ind < tableData.length; ind++) {
          allTextContent += tableData[ind].innerText;
      }
      
      if (allTextContent) {
        if (allTextContent.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }       
    }
  }

  componentDidMount() {
    axios.get(API_URL + "user/information/" + this.state.currentUserId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.token,
        'Email': this.state.currentUserEmail
      },
      })
      .then(response => {
        this.setState({
          error: false,
          message: ""
        });
        const currentUser = response.data.users.data.attributes;
        if (currentUser.id_role === 'regular') {
          const redirect = '/profile'
          this.setState({ redirect });
        } else {
          this.setState({ currentUser });
          // GET ALL SECTIONS
          axios.get(API_URL + "sections/information", {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': this.state.token,
              'Email': this.state.currentUserEmail
            },
          })
          .then(response => {
            const { data: allSections = [] } = response.data.sections;
            const totalCount = response.data.recordsTotal;
            this.setState({ allSections, totalCount });
          })
          .catch(err => {
            if (err.response.status === 401) {
              const redirect = '/login'
              this.setState({ redirect });
            } else {
              const profileError = err;
              this.setState({ profileError });
            }
          })
        }
      })
      .catch(err => {
        if (err.response.status === 401) {
            const redirect = '/login'
            this.setState({ redirect });
        } else {
            const profileError = err;
            this.setState({ profileError });
        }
      })
  }

  onPageChanged = data => {
    const { currentPage, pageLimit, sortAttribute, sortDirection } = data;

    if (sortDirection) {
      this.setState({ sortDirection, sortAttribute });
    }

    if (this.state.token) {
      axios.get(API_URL + `sections/information?page=${currentPage}&per_page=${pageLimit}&sort_by=${sortAttribute || 'id'}&sort_order=${sortDirection || this.state.sortDirection || 'asc'}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      })
      .then(response => {
        const { data: allSections = [] } = response.data.sections;
        const { currentPage, totalPages } = data;
        const { data: currentSections = [] } = response.data.sections;
        this.setState({ currentPage, currentSections, totalPages, allSections });
      })
      .catch(error => {
        console.log(error);
      })
    }
  }

  render() {
    const { currentSections, allSections, totalCount, profileError, redirect, currentPage, totalPages, sortAttribute, sortDirection } = this.state;
    const totalSections = totalCount || allSections.length;

    if (redirect) {
      return <Navigate to={redirect} />
    }

    if (totalSections === 0) return null;
    const headerClass = ['text-dark py-2 pr-4 m-0', currentPage ? 'border-gray border-right' : ''].join(' ').trim();

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { profileError.response.data.message }
      </div>
    }

    let oldSortAttribute = sortAttribute

    const sortClick = (newSortAttribute) => {
      this.onChangeSortAttribute({
        currentPage: currentPage,
        totalPages: totalPages,
        totalRecords: totalSections,
        sortAttribute: newSortAttribute,
        sortDirection: oldSortAttribute !== newSortAttribute && sortDirection === 'desc' ? 'asc' : sortDirection === 'asc' ? 'desc' : 'asc'
      });
    }

    return (
      <div className="container">
        {(currentSections) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-xl-8-table col-xl-8-profile">
              <div className="card">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                    <li className="breadcrumb-item">
                      <a className="link-body-emphasis" href="/admin">
                        <img src={homeIcon} alt="Admin Home" className="admin-home-icon" />
                        <span className="visually-hidden">Home</span>
                      </a>
                    </li>
                    <li className="breadcrumb-item">
                      <a className="link-body-emphasis fw-semibold text-decoration-none" href="/admin">Admin</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Sekcje
                    </li>
                  </ol>
                </nav>
                <div>
                  <div className="d-flex nav-profile flex-wrap align-items-center justify-content-center justify-content-lg-start">
                    <div className="admin-users-name">LISTA SEKCJI</div>
                    <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                    </ul>

                    <Form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
                      <Input type="search" id="tableSearchInput" ref="PwdInput" onChange={this.onChangeTableSearch} className="form-control" placeholder="Szukaj..." aria-label="Search" />
                    </Form>
                  </div>
                </div>
                <hr></hr>
                <div className="table-responsive">
                <table className="table table-striped" id="usersTable">
                  <thead>
                    <tr>
                      <th>ID <img src={sortAttribute === 'id' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie ID" className="admin-users-sort-icon" onClick={() => sortClick('id')} /></th>
                      <th>Nazwa <img src={sortAttribute === 'name' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sorowanie Nazwa" className="admin-users-sort-icon" onClick={() => sortClick('name')} /></th>
                      <th>Średni czas 9mm <img src={sortAttribute === 'average_time_9mm' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie 9mm" className="admin-users-sort-icon" onClick={() => sortClick('average_time_9mm')} /></th>
                      <th>Średni czas 5.56mm <img src={sortAttribute === 'average_time_556mm' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie 5.56mm" className="admin-users-sort-icon" onClick={() => sortClick('average_time_556mm')} /></th>
                      <th>Średni czas taktyczny <img src={sortAttribute === 'average_time_tactical' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie Taktyczny" className="admin-users-sort-icon" onClick={() => sortClick('average_time_tactical')} /></th>
                      <th>Ilość kursantów <img src={sortAttribute === 'users_count' && sortDirection === 'desc' ? tableSortDown : tableSortUp} alt="Sortowanie Taktyczny" className="admin-users-sort-icon" onClick={() => sortClick('users_count')} /></th>
                      <th>Opcje</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentSections.map((section, i) => {
                      return(
                        <tr key={i}>
                          <td className="admin-users-cell">{section.attributes.id}</td>
                          <td className="admin-users-cell">s{section.attributes.name}</td>
                          <td className="admin-users-cell">{section.attributes.average_time_9mm}</td>
                          <td className="admin-users-cell">{section.attributes.average_time_556mm}</td>
                          <td className="admin-users-cell">{section.attributes.average_time_tactical}</td>
                          <td className="admin-users-cell">{section.attributes.users_count}</td>
                          <td className="admin-users-cell">
                            <Link to={{pathname: '/admin/sections/' + section.attributes.id}} target="_self">
                              <img src={tableEditRow} alt="Edit Section" className="admin-edit-row-icon" />
                            </Link>
                          </td>
                        </tr>
                      )
                      })}
                  </tbody>
                </table>

                <div className="container mb-5 pagination-custom">
                  <div className="row d-flex flex-row py-5">
                    <div className="w-100 px-4 py-5 d-flex flex-row flex-wrap align-items-center justify-content-between">
                      <div className="d-flex flex-row align-items-center">
                        <h2 className={headerClass}>
                          <strong className="text-secondary">{totalSections}</strong> {totalSections > 1 ? 'Sekcje' : 'Sekcja'}
                        </h2>
                        { currentPage && (
                          <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                            Strona <span className="font-weight-bold">{ currentPage }</span> / <span className="font-weight-bold">{ totalPages }</span>
                          </span>
                        ) }
                      </div>
                      <div className="d-flex flex-row py-4 align-items-center">
                        <Pagination totalRecords={totalSections} pageLimit={PAGE_LIMIT} pageNeighbours={1} onPageChanged={this.onPageChanged} />
                      </div>
                    </div>
                  </div>
                  <Link to={{pathname: '/admin/sections/create'}} target="_self">
                    <button type="button" className="btn btn-outline-secondary">Nowa sekcja</button>
                  </Link>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
          : null}
      </div>
    );
  }
}