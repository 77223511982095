import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Register from "./components/register.component";
import AccountActivation from "./components/account-activation.component";
import Login from "./components/login.component";
import Profile from "./components/profile.component";
import ProfileEdit from "./components/profile-edit.component";
import UploadImage from "./components/upload-image.component"
import Admin from "./components/admin.component"
import AdminUsers from "./components/admin-users.component"
import AdminUsersEdit from "./components/admin-profile-edit.component";
import AdminSections from "./components/admin-sections.component"
import AdminSectionsEdit from "./components/admin-section-edit.component";
import AdminSectionsCreate from "./components/admin-section-create.component";
import AdminFacilities from "./components/admin-facilities.component"
import AdminFacilitiesEdit from "./components/admin-facility-edit.component";
import AdminFacilitiesCreate from "./components/admin-facility-create.component";
import AdminEquipments from "./components/admin-equipments.component"
import AdminEquipmentEdit from "./components/admin-equipment-edit.component";
import AdminEquipmentCreate from "./components/admin-equipment-create.component";
import AdminDrills from "./components/admin-drills.component"
import AdminDrillsEdit from "./components/admin-drill-edit.component";
import AdminDrillsCreate from "./components/admin-drill-create.component";
import AdminVerifications from "./components/admin-verifications.component";
import AdminVerificationsEdit from "./components/admin-verification-edit.component";
import AdminVerificationsCreate from "./components/admin-verification-create.component";
import AdminTrainings from "./components/admin-trainings.component";
import AdminTrainingsEdit from "./components/admin-training-edit.component";
import AdminTrainingCreate from "./components/admin-training-create.component";
import AdminTrainingRegistrations from "./components/admin-training-registrations.component";
import AdminTrainingRegistrationEdit from "./components/admin-training-registration-edit.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      token: false
    };
  }

  componentDidMount() {
    const userToken = JSON.parse(localStorage.getItem('poborowy_token')) 
    if (userToken !== null) {
      this.setState({
        token: userToken
      });
    }
  }

  logOut() {
    AuthService.logout();
    this.setState({
      token: false,
      currentUser: undefined
    });
  }

  render() {
    const { token } = this.state;
    
    return (
        <main>
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/register"} className="navbar-brand">
            Poborowy
          </Link>
          {token ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  Profil
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  Wyloguj
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Logowanie
                </Link>
              </li>
            </div>
          )}
          </nav>
          <div className="col-lg-8 mx-auto p-4 py-md-5">
            <div className="container mt-3">
              <Routes>
                <Route path="/" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/user_edit" element={<ProfileEdit />} />
                <Route path="/account_activation" element={<AccountActivation />} />
                <Route path="/upload_image" element={<UploadImage />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/users" element={<AdminUsers />} />
                <Route path="/admin/users/:user_id" element={<AdminUsersEdit />} />
                <Route path="/admin/sections" element={<AdminSections />} />
                <Route path="/admin/sections/:section_id" element={<AdminSectionsEdit />} />
                <Route path="/admin/sections/create" element={<AdminSectionsCreate />} />
                <Route path="/admin/facilities" element={<AdminFacilities />} />
                <Route path="/admin/facilities/:facility_id" element={<AdminFacilitiesEdit />} />
                <Route path="/admin/facilities/create" element={<AdminFacilitiesCreate />} />
                <Route path="/admin/equipment" element={<AdminEquipments />} />
                <Route path="/admin/equipment/:equipment_id" element={<AdminEquipmentEdit />} />
                <Route path="/admin/equipment/create" element={<AdminEquipmentCreate />} />
                <Route path="/admin/drills" element={<AdminDrills />} />
                <Route path="/admin/drills/:drill_id" element={<AdminDrillsEdit />} />
                <Route path="/admin/drills/create" element={<AdminDrillsCreate />} />
                <Route path="/admin/verifications" element={<AdminVerifications />} />
                <Route path="/admin/verifications/:verification_id" element={<AdminVerificationsEdit />} />
                <Route path="/admin/verifications/create" element={<AdminVerificationsCreate />} />
                <Route path="/admin/trainings" element={<AdminTrainings />} />
                <Route path="/admin/trainings/:verification_id" element={<AdminTrainingsEdit />} />
                <Route path="/admin/training/create" element={<AdminTrainingCreate />} />
                <Route path="/admin/training-registration-forms" element={<AdminTrainingRegistrations />} />
                <Route path="/admin/training-registration-edit/:id" element={<AdminTrainingRegistrationEdit />} />
              </Routes>
            </div>
          </div>
      </div>
        <footer className="pt-5 my-5 text-body-secondary border-top footer">
          Szkolenia Grom · © 2024r ·
        </footer>
      </main>
    )
  }
}

export default App;