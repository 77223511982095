import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { isEmail } from "validator";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import regFormLogo from "../custom_img/szkolenia_grom_logo.jpeg";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        To pole jest wymagane !
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadzono niepoprawny email.
      </div>
    );
  }
};

const phone = value => {
  if (value.length > 0 && value.length !== 9) {
    return (
      <div className="alert alert-danger" role="alert">
        Telefon musi posiadać 9 cyfr.
      </div>
    );
  }

  if (value.match(/^[0-9]+$/) === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Wpisz poprawny numer telefonu skłądający się z 9 cyfr.
      </div>
    );
  }
};

const vname = value => {
  if (value.length < 3 || value.length > 30) {
    return (
      <div className="alert alert-danger" role="alert">
        Imię musi posiadać od 3 do 30 znaków.
      </div>
    );
  }

  if (value.match(/^[AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż]+$/) === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Imię musi posiadać wyłącznie litery.
      </div>
    );
  }
};

const vsurname = value => {
  if (value.match(/^[AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż-]+$/) === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Nazwisko musi posiadać wyłącznie litery.
      </div>
    );
  }

  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        Naziwsko uytkownika musi posiadać od 3 do 20 znaków.
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        Hasło musi posiadać od 6 do 40 znaków.
      </div>
    );
  }
};

const vpassword_confirmation = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        Hasło musi posiadać od 6 do 40 znaków.
      </div>
    );
  }
};

export default class Register extends Component {
  constructor(props) {
    super(props);

    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeSurname = this.onChangeSurname.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePasswordConfirmation = this.onChangePasswordConfirmation.bind(this);
    this.setTokenFunc = this.setTokenFunc.bind(this);
    this.setRefreshReCaptcha = this.setRefreshReCaptcha.bind(this);

    this.state = {
      name: "",
      surname: "",
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
      successful: false,
      message: "",
      error: false,
      refreshReCaptcha: false,
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token'))
    };
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangeSurname(e) {
    this.setState({
      surname: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePhone(e) {
    this.setState({
      phone: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  onChangePasswordConfirmation(e) {
    this.setState({
      password_confirmation: e.target.value
    });
  }

  setTokenFunc(e) {
    const token = e
    this.setState({
      token: token
    })
  }

  setRefreshReCaptcha(e) {
    const refreshReCaptcha = e.refreshReCaptcha
    this.setState({
      refreshReCaptcha: refreshReCaptcha
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.setState({loading: true});
      AuthService.register(
        this.state.name,
        this.state.surname,
        this.state.email,
        this.state.phone,
        this.state.password,
        this.state.password_confirmation
      ).then(
        axios_res => {
          if (this.state.email !== axios_res.email) {
            this.setState({
              refreshReCaptcha: !this.state.refreshReCaptcha,
              message: this.handleError(axios_res),
              successful: false,
              error: true,
              loading: false
            });
          } else {
            this.setState({
              error: false,
              message: `Rejestracja zakończona sukcesem. Na adres ${axios_res.email} wysłaliśmy wiadomość aktywacyjną.`,
              successful: true,
              loading: false
            });
          }
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage,
            loading: false
          });
        }
      );
    }
  }

  componentDidMount() {
  }

  handleError(err) {
    if (!err.hasOwnProperty('response')) {
      err.response = {
          data: {
            details: "Wystąpił nieoczekiwany błąd."
          }
       }
    }
    if (Object.keys(err.response.data.details).length !== 0) {
      var response_error = JSON.stringify(err.response.data.details);
      if (response_error) {
        response_error = response_error.slice(1, response_error.length-1).replace(/"/g, "")
        response_error = response_error.split(',')
        response_error = response_error.join(".  ");
        return response_error
      }
    } else {
      return err.response.data.message
    }
  }

  render() {
    const { token, currentUserId } = this.state;

    if (token && currentUserId) {
      return <Navigate to='/profile' />
    }

    return (
      <div className="col-md-12">
        <div className="card card-container">
          <img
            src={regFormLogo}
            alt="Szkolenia Grom Rejestracja"
            className="profile-img-card"
          />

          <div className="page-name">REJESTRACJA</div>

          <Form
            onSubmit={this.handleRegister}
            ref={c => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="name">Imię użytkownika</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChangeName}
                    validations={[required, vname]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="surname">Nazwisko użytkownika</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="surname"
                    value={this.state.surname}
                    onChange={this.onChangeSurname}
                    validations={[required, vsurname]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">E-mail</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    validations={[required, email]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="phone">Numer telefonu</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.onChangePhone}
                    validations={[required, phone]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Hasło</label>
                  <Input
                    type="password"
                    className="form-control"
                    name="password"
                    autoComplete="on"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required, vpassword]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password-confirmation">Powtórz hasło</label>
                  <Input
                    type="password"
                    className="form-control"
                    name="password-confirmation"
                    autoComplete="on"
                    value={this.state.password_confirmation}
                    onChange={this.onChangePasswordConfirmation}
                    validations={[required, vpassword_confirmation]}
                  />
                </div>

                <div>
                  <GoogleReCaptcha
                    className="google-recaptcha-custom-class"
                    onVerify={this.state.token}
                    refreshReCaptcha={this.state.refreshReCaptcha}
                    hidden={true}
                  />
                </div>

                { 
                  this.state.password_confirmation !== this.state.password &&
                  <div className="alert alert-danger" role="alert">
                    Hasła się nie zgadzają.
                  </div>
                }

                {
                  this.state.password_confirmation === this.state.password &&
                  <div className="form-group button-form">
                    <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                      {this.state.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Rejestruj</span>
                    </button>
                  </div>
                }
              </div>
            )}

            {(this.state.successful || this.state.error) && (
              <div className="form-group">
                <div
                  className={
                    this.state.error
                      ? "alert alert-danger"
                      : "alert alert-success"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}