import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { isEmail } from "validator";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import axios from "axios";
import Swal from 'sweetalert2'
import homeIcon from "../custom_img/home_icon.png";
import '../custom_css/profile_edit.scss';

const API_URL = "https://poborowy.pl/";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        To pole jest wymagane !
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadzono niepoprawny email.
      </div>
    );
  }
};

const phone = value => {
  if (value.length > 0 && value.length !== 9) {
    return (
      <div className="alert alert-danger" role="alert">
        Telefon musi posiadać 9 cyfr.
      </div>
    );
  }

  if (value.match(/^[0-9]+$/) === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Wpisz poprawny numer telefonu skłądający się z 9 cyfr.
      </div>
    );
  }
};

const vname = value => {
  if (value.length < 3 || value.length > 30) {
    return (
      <div className="alert alert-danger" role="alert">
        Imię musi posiadać od 3 do 30 znaków.
      </div>
    );
  }

  if (value.match(/^[AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż]+$/) === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Imię musi posiadać wyłącznie litery.
      </div>
    );
  }
};

const vsurname = value => {
  if (value.match(/^[AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż-]+$/) === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Nazwisko musi posiadać wyłącznie litery.
      </div>
    );
  }

  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        Nazwisko musi posiadać od 3 do 20 znaków.
      </div>
    );
  }
};

export default class AdminProfileEdit extends Component {
  constructor(props) {
    super(props);
    this.handleUserEdit = this.handleUserEdit.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeSurname = this.onChangeSurname.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeSize = this.onChangeSize.bind(this);
    this.onChangeLefty = this.onChangeLefty.bind(this);
    this.onChangeSectionId = this.onChangeSectionId.bind(this);
    this.onChangeActive = this.onChangeActive.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.typeRoleClassification = this.typeRoleClassification.bind(this);

    this.state = {
      name: "",
      surname: "",
      email: "",
      phone: "",
      size: "",
      lefty: "",
      section_id: null,
      active: null,
      id_role: null,
      partActive: null,
      successful: false,
      message: "",
      redirect: null,
      loading: false,
      userReady: false,
      currentUser: null,
      profileError: null,
      error: false,
      refreshReCaptcha: false,
      loggedInUser: null,
      url_id: window.location.pathname.split('/')[3],
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  typeRoleClassification(role) {
    switch (role) {
      case 'regular':
        return 1;
      case 'instructor':
        return 2;
      case 'admin':
        return 3;
      default:
        return null;
    }
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangeSurname(e) {
    this.setState({
      surname: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePhone(e) {
    this.setState({
      phone: e.target.value
    });
  }

  onChangeSize(e) {
    this.setState({
      // size: e[0] ? e[0].label : ''
      size: e.target ? e.target.value : ""
    });
  }

  onChangeLefty(e) {
    this.setState({
      lefty: e.target.value
    });
  }

  onChangeSectionId(e) {
    this.setState({
      section_id: e.target.value
    });
  }

  onChangeActive(e) {
    this.setState({
      active: e.target.checked
    });
  }

  onChangeRole(e) {
    this.setState({
      id_role: e.target.value
    });
  }

  handleUserEdit(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
      loading: true
    });

    this.form.validateAll();

    var data = {
      name: this.state.name,
      surname: this.state.surname,
      email: this.state.email,
      phone: this.state.phone,
      size: this.state.size,
      lefty: this.state.lefty ? (this.state.lefty).toString() : this.state.lefty,
      section_id: parseInt(this.state.section_id),
      active: this.state.active,
      id_role: this.typeRoleClassification(this.state.id_role)
    }

    if (this.checkBtn.context._errors.length === 0) {
      axios.put(API_URL + "user/information/" + this.state.url_id, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      }).then(
        axios_res => {
          if (this.state.email !== axios_res.data.email) {
            this.setState({
              message: this.handleError(axios_res),
              successful: false,
              error: true,
              loading: false
            });
          } else {
            this.setState({
              loading: false,
              redirect: '/admin/users'
            });
            Swal.fire({
              icon: "success",
              title: "Zaktualizowano !",
              text: "Użytkownik został poprawnie zaktualizowany"
            });
          }
        },
        error => {
          const resMessage = error.response.data.details || error.response.data.message || error.toString();
          this.setState({
            successful: false,
            message: resMessage,
            error: true,
            loading: false
          });
        }
      );
    }
  }

  handleUserDelete(e) {
    this.setState({
      message: "",
      successful: false,
      loading: false
    });

    Swal.fire({
      title: 'Czy napewno usunąć użytkownika ?',
      text: `Usuwasz konto (${e}). Nie będziesz w stanie cofnąć tej akcji !`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Usuń',
      cancelButtonText: 'Anuluj'
    }).then((result) => {
      if (result.value) {
        axios.delete(API_URL + "user/information/" + this.state.url_id, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(res => {
          const redirect = '/admin/users'
          this.setState({ redirect });
          Swal.fire({
            icon: "success",
            title: "Usunięto!",
            text: "Użytkownik został usunięty z systemu"
          }
        )})
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.handleError(err),
            footer: '<a href>Spróbuj odświeżyć stronę</a>'
          })
        })
      }
    })
  }

  componentDidMount() {
    // GET LOGGEDIN USER
    axios.get(API_URL + "user/information/" + this.state.currentUserId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.token,
        'Email': this.state.currentUserEmail
      },
    })
    .then(response => {
      const loggedInUser = response.data.users.data.attributes;
      if (loggedInUser.id_role === 'regular') {
        const redirect = '/login'
        this.setState({ redirect });
      }
      // GET USER DATA
      axios.get(API_URL + "user/information/" + this.state.url_id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      })
      .then(response => {
        this.setState({error: false});
        const currentUser = response.data.users.data.attributes;
        const userReady = true;
        const name = currentUser.name;
        const surname = currentUser.surname;
        const email = currentUser.email;
        const phone = currentUser.phone;
        const size = currentUser.size;
        const lefty = currentUser.lefty ? currentUser.lefty.toString() : currentUser.lefty
        const section_id = currentUser.section_id
        const active = currentUser.active
        const id_role = currentUser.id_role
        this.setState({ currentUser, userReady, name, surname, email, phone, size, lefty, section_id, active, id_role, loggedInUser });

        // GET REGISTRATION DATA FOR USER
        axios.get(API_URL + "user_registration/" + currentUser.id, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(res => {
          const partActive = res.data.registrations.data.attributes.utilized
          this.setState({ partActive });
        })
        .catch(err => {
          const profileError = err;
          this.setState({ profileError });
        })

        // GET AVAILABLE SECTIONS
        axios.get(API_URL + "sections/information", {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': this.state.token,
              'Email': this.state.currentUserEmail
            },
          })
          .then(res => {
            const allSections = res.data.sections.data
            var sel = document.getElementById('section_id');
              if (allSections) {
                var empty_opt = document.createElement('option');
                empty_opt.innerHTML = '...';
                empty_opt.value = null;
                sel.appendChild(empty_opt);
                for(var i = 0; i < allSections.length; i++) {
                  var opt = document.createElement('option');
                  opt.innerHTML = 's'+allSections[i].attributes.name;
                  opt.value = allSections[i].id;
                  if (parseInt(allSections[i].id) === section_id) {
                    opt.selected = true;
                  }
                  sel.appendChild(opt);
                }
              }
          })
          .catch(err => {
            const profileError = err;
            this.setState({ profileError });
          })
      })
      .catch(err => {
        if (err.response.status === 401) {
          const redirect = '/login'
          this.setState({ redirect });
        } else if (err.response.status === 404) {
          this.setState({
            successful: false,
            profileError: `Nie znaleziono użytkownika z ID ${this.state.url_id}`,
            error: true
          });
        } else {
          const profileError = err;
          this.setState({ profileError });
        }
      })
    })
    .catch(err => {
      const profileError = err;
      this.setState({ profileError });
    })
  }

  handleError(err) {
    if (!err.hasOwnProperty('response')) {
      err.response = {
          data: {
            details: "Wystąpił nieoczekiwany błąd."
          }
       }
    }
    if (Object.keys(err.response.data.details).length !== 0) {
      var response_error = JSON.stringify(err.response.data.details);
      if (response_error) {
        response_error = response_error.slice(1, response_error.length-1).replace(/"/g, "")
        response_error = response_error.split(',')
        response_error = response_error.join(".  ");
        return response_error
      }
    } else {
      return err.response.data.message
    }
  }

  render() {
    const { userReady, currentUser, profileError, redirect, partActive, active, loggedInUser } = this.state;

    if (redirect) {
      return <Navigate to={redirect} />
    }

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { profileError.response ? profileError.response.data.message : profileError }
      </div>
    }

    return (
      <div className="container">
        {(userReady) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="card">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis" href="/admin">
                          <img src={homeIcon} alt="Admin Home" className="admin-home-icon" />
                          <span className="visually-hidden">Home</span>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis fw-semibold text-decoration-none" href="/admin">Admin</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        <Link to={{pathname: '/admin/users'}} target="_self">
                          Kursanci
                        </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Profil użytkownika
                      </li>
                    </ol>
                    { partActive ? <span className="badge rounded-pill text-bg-success">Potwierdził rejestracje</span> : <span className="badge rounded-pill text-bg-warning">Nie potwierdził rejestracji</span>}
                  </nav>
                <div className="card-body pb-0">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="text-center">
                        <img src={`../../images/${currentUser.image}`} className="img-fluid avatar-xxl rounded-circle" alt=""></img>
                        <div className="edit-profile"></div>
                        <h4 className="text-primary font-size-20 mt-3 mb-2">{ currentUser.username }</h4>
                        <h5 className="text-muted font-size-13 mb-0">
                          {(currentUser.id_role === 'admin') ?
                            <div className="mb-2-profile-admin">
                              <span className="badge border border-danger-subtle text-danger-emphasis rounded-pill">Administrator Serwisu</span>
                            </div> : (currentUser.section_id === null ? <small className="d-inline-flex px-2 py-1 fw-semibold text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 me-2">oczekuje na nadanie numeru sekcji</small> : 'Sekcja: ' + currentUser.section_id)
                          }
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ms-3">
                        <div>
                          <h4 className="card-title mb-2 mb-2-profile">Edycja Użytkownika - { currentUser.name }</h4>
                        </div>
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div>
                              <Form
                                onSubmit={this.handleUserEdit}
                                ref={c => {
                                  this.form = c;
                                }}
                              >
                                {!this.state.successful && (
                                  <div>
                                    <div className="form-group">
                                      <label htmlFor="name">Imię użytkownika</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={currentUser.name}
                                        onChange={this.onChangeName}
                                        validations={[required, vname]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="surname">Nazwisko użytkownika</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="surname"
                                        value={currentUser.surname}
                                        onChange={this.onChangeSurname}
                                        validations={[required, vsurname]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="email">E-mail</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        value={currentUser.email}
                                        onChange={this.onChangeEmail}
                                        validations={[required, email]}
                                        disabled={true}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="phone">Numer telefonu</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="phone"
                                        value={currentUser.phone}
                                        onChange={this.onChangePhone}
                                        validations={[required, phone]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="size">Rozmiar ubrania</label>
                                      <select name="size" id="size" className="form-control select-size-first" onChange={this.onChangeSize} value={this.state.size || ""} required={loggedInUser.id_role === 'admin' ? false : true}>
                                        <option value="">...</option>
                                        <option value="XS">XS</option>
                                        <option value="S">S</option>
                                        <option value="M">M</option>
                                        <option value="L">L</option>
                                        <option value="XL">XL</option>
                                        <option value="XXL">XXL</option>
                                      </select>
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="lefty">Leworęczny ?</label>
                                      <div className="form-check">
                                        <Input 
                                          type="radio"
                                          id="lefty_yes"
                                          name="lefty"
                                          className="form-check-input"
                                          checked={currentUser.lefty ? 1 : 0}
                                          required={loggedInUser.id_role === 'regular' ? true : false}
                                          value={'true'}
                                          onChange={this.onChangeLefty}
                                        />
                                        <label className="form-check-label" htmlFor="lefty_yes">Tak</label>
                                      </div>
                                      <div className="form-check">
                                        <Input
                                          type="radio"
                                          id="lefty_no"
                                          name="lefty"
                                          className="form-check-input"
                                          checked={(currentUser.lefty === null || currentUser.lefty === true) ? 0 : 1}
                                          required={loggedInUser.id_role === 'regular' ? true : false}
                                          value={'false'}
                                          onChange={this.onChangeLefty}
                                        />
                                        <label className="form-check-label" htmlFor="lefty_no">Nie</label>
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor="section_id">Identyfikator sekcji</label>
                                        <select name="section_id" id="section_id" className="form-control select-size-first" onChange={this.onChangeSectionId} value={this.state.section_id || ""} required={loggedInUser.id_role === 'admin' ? false : true} />
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor="active">Może się logować ?</label>
                                        <Input
                                          type="checkbox"
                                          className="form-check-input"
                                          name="active"
                                          value={active}
                                          onChange={this.onChangeActive}
                                          checked={currentUser.active}
                                          disabled={partActive ? false : true}
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor="id_role">Rola</label>
                                        <select name="id_role" id="id_role" className="form-control select-size-first" onChange={this.onChangeRole} value={this.state.id_role || ""} disabled={loggedInUser.id_role === 'admin' ? false : true}>
                                          <option value="regular">regular</option>
                                          <option value="instructor">instructor</option>
                                          <option value="admin">administrator</option>
                                        </select>
                                      </div>
                                    </div>
                                    <p />

                                    {
                                      <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                        {this.state.loading && (
                                          <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Zapisz</span>
                                      </button>
                                    }

                                    {
                                      <button type="button" className="btn btn-primary btn-danger delete-user-button" onClick={this.handleUserDelete.bind(this, currentUser.email)}>
                                        Usuń użytkownika
                                      </button>
                                    }
                                  </div>
                                )}

                                {(this.state.successful || this.state.error) && (
                                  <div className="form-group">
                                    <div
                                      className={
                                        this.state.error
                                          ? "alert alert-danger"
                                          : "alert alert-success"
                                      }
                                      role="alert"
                                    >
                                      {this.state.message}
                                    </div>
                                  </div>
                                )}
                                <CheckButton
                                  style={{ display: "none" }}
                                  ref={c => {
                                    this.checkBtn = c;
                                  }}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
          : null}
      </div>
    )
  }
}