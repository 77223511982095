import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import axios from "axios";
import Swal from 'sweetalert2'
import homeIcon from "../custom_img/home_icon.png";
import tableEditRow from "../custom_img/table_edit_row.png";
import '../custom_css/profile_edit.scss';

const API_URL = "https://poborowy.pl/";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        To pole jest wymagane !
      </div>
    );
  }
};

const secName = value => {
  if (value && value < 1) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawny numer sekcji
      </div>
    );
  }
};

const av9mm = value => {
  if (value && value < 0) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną średnią 9mm
      </div>
    );
  }
};

const av556mm = value => {
  if (value && value < 0) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną średnią 5.56mm
      </div>
    );
  }
};

const avtactical = value => {
  if (value && value < 1) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną średnią taktyczną
      </div>
    );
  }
};

export default class AdminSectionsEdit extends Component {
  constructor(props) {
    super(props);
    this.handleSectionEdit = this.handleSectionEdit.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeAverageTime9mm = this.onChangeAverageTime9mm.bind(this);
    this.onChangeAverageTime556mm = this.onChangeAverageTime556mm.bind(this);
    this.onChangeAverageTimeTactical = this.onChangeAverageTimeTactical.bind(this);

    this.state = {
      name: "",
      average_time_9mm: "",
      average_time_556mm: "",
      average_time_tactical: "",
      currentSection: null,
      currentUser: null,
      currentSectionUsers: null,
      url_id: window.location.pathname.split('/')[3],
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangeAverageTime9mm(e) {
    this.setState({
      average_time_9mm: e.target.value
    });
  }

  onChangeAverageTime556mm(e) {
    this.setState({
      average_time_556mm: e.target.value
    });
  }

  onChangeAverageTimeTactical(e) {
    this.setState({
      average_time_tactical: e.target.value
    });
  }

  handleSectionEdit(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
      loading: true
    });

    this.form.validateAll();

    var data = {
      name: parseInt(this.state.name),
      average_time_9mm: parseFloat(this.state.average_time_9mm).toFixed(2),
      average_time_556mm: parseFloat(this.state.average_time_556mm).toFixed(2),
      average_time_tactical: parseInt(this.state.average_time_tactical)
    }

    if (this.checkBtn.context._errors.length === 0) {
      axios.put(API_URL + "sections/information/" + this.state.url_id, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      }).then(
        axios_res => {
          this.setState({
            loading: false,
            redirect: '/admin/sections'
          });
          Swal.fire({
            icon: "success",
            title: "Zaktualizowano !",
            text: "Sekcja została poprawnie zaktualizowana"
          });
        },
        error => {
          const resMessage = error.response.data.details || error.response.data.message || error.toString();

          this.setState({
            successful: false,
            message: resMessage,
            error: true,
            loading: false
          });
        }
      );
    }
  }

  handleSectionDelete(e) {
    this.setState({
      message: "",
      successful: false,
      loading: false
    });

    Swal.fire({
      title: 'Czy napewno usunąć sekcje ?',
      text: `Usuwasz sekcje ${e}. Nie będziesz w stanie cofnąć tej akcji !`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Usuń',
      cancelButtonText: 'Anuluj'
    }).then((result) => {
      if (result.value) {
        axios.delete(API_URL + "sections/information/" + this.state.url_id, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(res => {
          const redirect = '/admin/sections'
          this.setState({ redirect });
          Swal.fire({
            icon: "success",
            title: "Usunięto!",
            text: "Sekcja została usunięta z systemu"
          }
        )})
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.handleError(err),
            footer: '<a href>Spróbuj odświeżyć stronę</a>'
          })
        })
      }
    })
  }

  componentDidMount() {
    // GET USER DATA
    axios.get(API_URL + "user/information/" + this.state.currentUserId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.token,
        'Email': this.state.currentUserEmail
      },
    })
    .then(response => {
      this.setState({error: false});
      const currentUser = response.data.users.data.attributes;
      const userReady = true;
      if (currentUser.id_role === 'regular') {
        const redirect = '/login'
        this.setState({ redirect });
      }
      // GET SECTION DATA
      axios.get(API_URL + "sections/information/" + this.state.url_id, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(res => {
          const currentSection = res.data.sections.data.attributes;
          const name =  currentSection.name;
          const average_time_9mm = currentSection.average_time_9mm;
          const average_time_556mm = currentSection.average_time_556mm;
          const average_time_tactical = currentSection.average_time_tactical;
          // GET SECTION USERS DATA
          axios.get(API_URL + "sections/users/information/" + this.state.url_id, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': this.state.token,
              'Email': this.state.currentUserEmail
            },
          })
          .then(res => {
            const currentSectionUsers = res.data.sections.data
            this.setState({
              currentSection,
              currentUser,
              userReady,
              currentSectionUsers,
              name,
              average_time_9mm,
              average_time_556mm,
              average_time_tactical
            });
          })
          .catch(err => {
            this.setState({
              successful: false,
              profileError: `Nie mogłem pobrać użytkowników sekcji`,
              error: true
            });
          })
        })
        .catch(err => {
          this.setState({
            successful: false,
            profileError: `Nie znaleziono sekcji ${this.state.url_id}`,
            error: true
          });
        })
    })
    .catch(err => {
      if (err.response.status === 401) {
        const redirect = '/login'
        this.setState({ redirect });
      } else if (err.response.status === 404) {
        this.setState({
          successful: false,
          profileError: `Nie znaleziono użytkownika z ID ${this.state.url_id}`,
          error: true
        });
      } else {
        const profileError = err;
        this.setState({ profileError });
      }
    })
  }

  handleError(err) {
    if (!err.hasOwnProperty('response')) {
      err.response = {
          data: {
            details: "Wystąpił nieoczekiwany błąd."
          }
       }
    }
    if (Object.keys(err.response.data.details).length !== 0) {
      var response_error = JSON.stringify(err.response.data.details);
      if (response_error) {
        response_error = response_error.slice(1, response_error.length-1).replace(/"/g, "")
        response_error = response_error.split(',')
        response_error = response_error.join(".  ");
        return response_error
      }
    } else {
      return err.response.data.message
    }
  }

  render() {
    const { userReady, currentUser, currentSection, currentSectionUsers, profileError, redirect } = this.state;

    if (redirect) {
      return <Navigate to={redirect} />
    }

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { profileError.response ? profileError.response.data.message : profileError }
      </div>
    }

    return (
      <div className="container">
        {(userReady) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="card">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis" href="/admin">
                          <img src={homeIcon} alt="Admin Home" className="admin-home-icon" />
                          <span className="visually-hidden">Home</span>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis fw-semibold text-decoration-none" href="/admin">Admin</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        <Link to={{pathname: '/admin/sections'}} target="_self">
                          Sekcje
                        </Link>  
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Profil sekcji
                      </li>
                    </ol>
                  </nav>
                <div className="card-body pb-0">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="text-center">
                        <div className="edit-profile"></div>
                        <h4 className="text-primary font-size-20 mt-3 mb-2">{ currentUser.username }</h4>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ms-3">
                        <div>
                          <h4 className="card-title mb-2 mb-2-profile">Sekcja {currentSection.name}</h4>
                        </div>
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div>
                              <Form
                                onSubmit={this.handleSectionEdit}
                                ref={c => {
                                  this.form = c;
                                }}
                              >
                                {!this.state.successful && (
                                  <div>
                                    <div className="form-group">
                                      <label htmlFor="name">Numer sekcji</label>
                                      <Input
                                        type="number"
                                        className="form-control"
                                        name="name"
                                        value={currentSection.name}
                                        onChange={this.onChangeName}
                                        validations={[required, secName]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="average_time_9mm">Średni czas sekcji 9mm</label>
                                      <Input
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        name="average_time_9mm"
                                        value={currentSection.average_time_9mm}
                                        onChange={this.onChangeAverageTime9mm}
                                        validations={[required, av9mm]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="average_time_556mm">Średni czas sekcji 5.56mm</label>
                                      <Input
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        name="average_time_556mm"
                                        value={currentSection.average_time_556mm}
                                        onChange={this.onChangeAverageTime556mm}
                                        validations={[required, av556mm]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="average_time_tactical">Średni czas taktyczny sekcji</label>
                                      <Input
                                        type="number"
                                        className="form-control"
                                        name="average_time_tactical"
                                        value={currentSection.average_time_tactical}
                                        onChange={this.onChangeAverageTimeTactical}
                                        validations={[required, avtactical]}
                                      />
                                    </div>
                                    <p />

                                    {
                                      <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                        {this.state.loading && (
                                          <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Zapisz</span>
                                      </button>
                                    }

                                    {
                                      <button disabled={currentSectionUsers.length === 0 ? false : true} type="button" className="btn btn-primary btn-danger delete-user-button" onClick={this.handleSectionDelete.bind(this, currentSection.name)}>
                                        Usuń sekcje
                                      </button>
                                    }
                                  </div>
                                )}
                                <p></p>
                                <h6 className="card-title mb-2 mb-2-profile">KURSANCI SEKCJI</h6>
                                <table className="table table-striped" id="usersTable">
                                  <thead>
                                    <tr>
                                      <th>ID</th>
                                      <th>Imię</th>
                                      <th>Nazwisko</th>
                                      <th>Telefon</th>
                                      <th>Email</th>
                                      <th>Opcje</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {currentSectionUsers.map((user, i) => {
                                      return(
                                        <tr key={i}>
                                          <td className="admin-users-cell">{user.attributes.id}</td>
                                          <td className="admin-users-cell">{user.attributes.name}</td>
                                          <td className="admin-users-cell">{user.attributes.surname}</td>
                                          <td className="admin-users-cell">{user.attributes.phone}</td>
                                          <td className="admin-users-cell">{user.attributes.email}</td>
                                          <td className="admin-users-cell">
                                            <Link to={{pathname: '/admin/users/' + user.attributes.id}} target="_self">
                                              <img src={tableEditRow} alt="Edit User" className="admin-edit-row-icon" />
                                            </Link>
                                          </td>
                                        </tr>
                                      )
                                      })}
                                  </tbody>
                                </table>
                                {(this.state.successful || this.state.error) && (
                                  <div className="form-group">
                                    <div
                                      className={
                                        this.state.error
                                          ? "alert alert-danger"
                                          : "alert alert-success"
                                      }
                                      role="alert"
                                    >
                                      {this.state.message}
                                    </div>
                                  </div>
                                )}
                                <CheckButton
                                  style={{ display: "none" }}
                                  ref={c => {
                                    this.checkBtn = c;
                                  }}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
          : null}
      </div>
    )
  }
}