import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import axios from "axios";
import Swal from 'sweetalert2'
import homeIcon from "../custom_img/home_icon.png";
import '../custom_css/profile_edit.scss';

const API_URL = "https://poborowy.pl/";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        To pole jest wymagane !
      </div>
    );
  }
};

const eqName = value => {
  if (value && value.length < 3) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną nazwę sprzętu.
      </div>
    );
  }
};

const eqDescription = value => {
  if (value && value.length < 5) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawny opis sprzętu, minimum 5 znaków.
      </div>
    );
  }
};

const eqTypeId = value => {
  if (value === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawny typ sprzętu.
      </div>
    );
  }
};

export default class AdminEquipmentCreate extends Component {
  constructor(props) {
    super(props);
    this.handleEquipmentCreate = this.handleEquipmentCreate.bind(this);
    this.typeIdClassification = this.typeIdClassification.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeCaliber = this.onChangeCaliber.bind(this);
    this.onChangePrice = this.onChangePrice.bind(this);

    this.state = {
      name: null,
      description: null,
      id_type: null,
      caliber: null,
      base_price: null,
      loading: false,
      url_id: window.location.pathname.split('/')[3],
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  typeIdClassification(type) {
    switch (type) {
      case 'ammunition':
        return 1;
      case 'weapon':
        return 2;
      case 'other':
        return 3;
      default:
        return null;
    }
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value
    });
  }

  onChangeType(e) {
    this.setState({
      id_type: e.target.value
    });
  }

  onChangeCaliber(e) {
    this.setState({
      caliber: e.target.value
    });
  }

  onChangePrice(e) {
    this.setState({
      base_price: e.target.value
    });
  }

  handleEquipmentCreate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    var data = {
      name: this.state.name,
      description: this.state.description,
      id_type: this.typeIdClassification(this.state.id_type),
      caliber: this.state.caliber,
      base_price: this.state.base_price ? parseFloat(this.state.base_price).toFixed(2) : this.state.base_price
    }

    if (this.checkBtn.context._errors.length === 0) {
      this.setState({loading: true});
      axios.post(API_URL + "equipment/information", data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      }).then(
        axios_res => {
          if (this.state.name !== axios_res.data.equipment.data.attributes.name) {
            this.setState({
              message: this.handleError(axios_res),
              successful: false,
              error: true,
              loading: false
            });
          } else {
            this.setState({
              loading: false,
              redirect: '/admin/equipment'
            });
            Swal.fire({
              icon: "success",
              title: "Utworzono !",
              text: "Sprzęt został poprawnie utworzony"
            });
          }
        },
        error => {
          const resMessage = error.response.data.details || error.response.data.message || error.toString();
          this.setState({
            successful: false,
            message: resMessage,
            error: true,
            loading: false
          });
        }
      );
    }
  }

  componentDidMount() {
    // GET USER DATA
    axios.get(API_URL + "user/information/" + this.state.currentUserId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.token,
        'Email': this.state.currentUserEmail
      },
    })
    .then(response => {
      this.setState({error: false});
      const currentUser = response.data.users.data.attributes;
      const userReady = true;
      if (currentUser.id_role === 'regular') {
        const redirect = '/login'
        this.setState({ redirect });
      }
      this.setState({ userReady });
    })
    .catch(err => {
      if (err.response.status === 401) {
        const redirect = '/login'
        this.setState({ redirect });
      } else if (err.response.status === 404) {
        this.setState({
          successful: false,
          profileError: `Wystąpił błąd lub nie znaleziono użytkownika z ID ${this.state.url_id}`,
          error: true
        });
      } else {
        const profileError = err;
        this.setState({ profileError });
      }
    })
  }

  handleError(err) {
    if (!err.hasOwnProperty('response')) {
      err.response = {
          data: {
            details: "Wystąpił nieoczekiwany błąd."
          }
       }
    }
    if (Object.keys(err.response.data.details).length !== 0) {
      var response_error = JSON.stringify(err.response.data.details);
      if (response_error) {
        response_error = response_error.slice(1, response_error.length-1).replace(/"/g, "")
        response_error = response_error.split(',')
        response_error = response_error.join(".  ");
        return response_error
      }
    } else {
      return err.response.data.message
    }
  }

  render() {
    const { userReady, profileError, redirect } = this.state;

    if (redirect) {
      return <Navigate to={redirect} />
    }

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { profileError.response ? profileError.response.data.message : profileError }
      </div>
    }

    return (
      <div className="container">
        {(userReady) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="card">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis" href="/admin">
                          <img src={homeIcon} alt="Admin Home" className="admin-home-icon" />
                          <span className="visually-hidden">Home</span>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis fw-semibold text-decoration-none" href="/admin">Admin</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        <Link to={{pathname: '/admin/equipment'}} target="_self">
                          Sprzęt
                        </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Dodaj sprzęt
                      </li>
                    </ol>
                  </nav>
                <div className="card-body pb-0">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="text-center">
                        <div className="edit-profile"></div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ms-3">
                        <div>
                          <h4 className="card-title mb-2 mb-2-profile">DODAJ SPRZĘT</h4>
                        </div>
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div>
                              <Form
                                onSubmit={this.handleEquipmentCreate}
                                ref={c => {
                                  this.form = c;
                                }}
                              >
                                {!this.state.successful && (
                                  <div>
                                    <div className="form-group">
                                      <label htmlFor="name">Nazwa Sprzętu</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.onChangeName}
                                        validations={[required, eqName]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="description">Opis Sprzętu</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="description"
                                        value={this.state.description}
                                        onChange={this.onChangeDescription}
                                        validations={[required, eqDescription]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="id_type">Typ</label>
                                      <select name="id_type" id="id_type" className="form-control select-size-first" onChange={this.onChangeType} value={this.state.id_type || ""} required={true} validations={[eqTypeId]}>
                                        <option value=''>...</option>
                                        <option value="ammunition">amunicja</option>
                                        <option value="weapon">broń</option>
                                        <option value="other">inne</option>
                                      </select>
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="caliber">Kaliber</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="caliber"
                                        value={this.state.caliber}
                                        onChange={this.onChangeCaliber}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="base_price">Cena Bazowa</label>
                                      <Input
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        name="base_price"
                                        value={this.state.base_price}
                                        onChange={this.onChangePrice}
                                      />
                                    </div>
                                    <p />

                                    {
                                      <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                        {this.state.loading && (
                                          <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Zapisz</span>
                                      </button>
                                    }
                                  </div>
                                )}
                                {(this.state.successful || this.state.error) && (
                                  <div className="form-group">
                                    <div
                                      className={
                                        this.state.error
                                          ? "alert alert-danger"
                                          : "alert alert-success"
                                      }
                                      role="alert"
                                    >
                                      {this.state.message}
                                    </div>
                                  </div>
                                )}
                                <CheckButton
                                  style={{ display: "none" }}
                                  ref={c => {
                                    this.checkBtn = c;
                                  }}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
          : null}
      </div>
    )
  }
}