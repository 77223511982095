import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import axios from "axios";
import Swal from 'sweetalert2'
import homeIcon from "../custom_img/home_icon.png";
import '../custom_css/profile_edit.scss';

const API_URL = "https://poborowy.pl/";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        To pole jest wymagane !
      </div>
    );
  }
};

const drName = value => {
  if (value && value.length < 3) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną nazwę ćwiczenia.
      </div>
    );
  }
};

const drDescription = value => {
  if (value && value.length < 5) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawny opis ćwiczenia
      </div>
    );
  }
};

const drTimeLimit = value => {
  if (value && value.length <= 0) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź limit czasowy ćwiczenia
      </div>
    );
  }
};

const drDq = value => {
  if (value && value.length <= 0) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawny czas dyskwalifikacji
      </div>
    );
  }
};

const drApproaches = value => {
  if (value && value.length <= 0) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź ilość zajazdów
      </div>
    );
  }
};

const dr9mm = value => {
  if (value && value.length <= 0) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną ilość amunicji 9mm
      </div>
    );
  }
};

const dr556mm = value => {
  if (value && value.length <= 0) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną ilość amunicji 556mm
      </div>
    );
  }
};

export default class AdminDrillEdit extends Component {
  constructor(props) {
    super(props);
    this.handleDrillEdit = this.handleDrillEdit.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeTimeLimit = this.onChangeTimeLimit.bind(this);
    this.onChangeDq = this.onChangeDq.bind(this);
    this.onChangeApproaches = this.onChangeApproaches.bind(this);
    this.onChange9mm = this.onChange9mm.bind(this);
    this.onChange556mm = this.onChange556mm.bind(this);

    this.state = {
      name: null,
      description: null,
      time_limit: null,
      dq: null,
      approaches: null,
      mm9: null,
      mm556: null,
      url_id: window.location.pathname.split('/')[3],
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value
    });
  }

  onChangeTimeLimit(e) {
    this.setState({
      time_limit: e.target.value,
      dq: e.target.value * 2
    });
  }

  onChangeDq(e) {
    this.setState({
      dq: e.target.value
    });
  }

  onChangeApproaches(e) {
    this.setState({
      approaches: e.target.value
    });
  }

  onChange9mm(e) {
    this.setState({
      mm9: e.target.value
    });
  }

  onChange556mm(e) {
    this.setState({
      mm556: e.target.value
    });
  }

  handleDrillEdit(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    var data = {
      name: this.state.name,
      description: this.state.description,
      time_limit: parseFloat(this.state.time_limit).toFixed(2),
      dq: parseFloat(this.state.dq).toFixed(2),
      approaches: parseInt(this.state.approaches),
      mm9: parseInt(this.state.mm9),
      mm556: parseInt(this.state.mm556)
    }

    if (this.checkBtn.context._errors.length === 0) {
      this.setState({loading: true});
      axios.put(API_URL + "drills/information/" + this.state.url_id, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      }).then(
        axios_res => {
          this.setState({
            loading: false,
            redirect: '/admin/drills'
          });
          Swal.fire({
            icon: "success",
            title: "Zaktualizowano !",
            text: "Drill został poprawnie zaktualizowany"
          });
        },
        error => {
          const resMessage = error.response.data.details || error.response.data.message || error.toString();
          this.setState({
            successful: false,
            message: resMessage,
            error: true,
            loading: false
          });
        }
      );
    }
  }

  handleDrillDelete(e) {
    this.setState({
      message: "",
      successful: false,
      loading: false
    });

    Swal.fire({
      title: 'Czy napewno usunąć ćwiczenie ?',
      text: `Usuwasz drill'a "${e}". Nie będziesz w stanie cofnąć tej akcji !`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Usuń',
      cancelButtonText: 'Anuluj'
    }).then((result) => {
      if (result.value) {
        axios.delete(API_URL + "drills/information/" + this.state.url_id, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(res => {
          const redirect = '/admin/drills'
          this.setState({ redirect });
          Swal.fire({
            icon: "success",
            title: "Usunięto!",
            text: "Drill został usunięty z systemu"
          }
        )})
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.handleError(err),
            footer: '<a href>Spróbuj odświeżyć stronę</a>'
          })
        })
      }
    })
  }

  componentDidMount() {
    // GET USER DATA
    axios.get(API_URL + "user/information/" + this.state.currentUserId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.token,
        'Email': this.state.currentUserEmail
      },
    })
    .then(response => {
      this.setState({error: false});
      const currentUser = response.data.users.data.attributes;
      const userReady = true;
      if (currentUser.id_role === 'regular') {
        const redirect = '/login'
        this.setState({ redirect });
      }
      // GET DRILL DATA
      axios.get(API_URL + "drills/information/" + this.state.url_id, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(res => {
          const currentDrill = res.data.drills.data.attributes;
          const name =  currentDrill.name;
          const description = currentDrill.description;
          const time_limit = currentDrill.time_limit;
          const dq = currentDrill.dq;
          const approaches = currentDrill.approaches;
          const mm9 = currentDrill.mm9;
          const mm556 = currentDrill.mm556;
          this.setState({ currentUser, currentDrill, userReady, name, description, time_limit, dq, approaches, mm9, mm556 });
        })
        .catch(err => {
          this.setState({
            successful: false,
            profileError: `Wystąpił problem z pobraniem drill'a o ID ${this.state.url_id}`,
            error: true
          });
        })
    })
    .catch(err => {
      if (err.response.status === 401) {
        const redirect = '/login'
        this.setState({ redirect });
      } else if (err.response.status === 404) {
        this.setState({
          successful: false,
          profileError: `Wystąpił błąd lub nie znaleziono użytkownika z ID ${this.state.url_id}`,
          error: true
        });
      } else {
        const profileError = err;
        this.setState({ profileError });
      }
    })
  }

  handleError(err) {
    if (!err.hasOwnProperty('response')) {
      err.response = {
          data: {
            details: "Wystąpił nieoczekiwany błąd."
          }
       }
    }
    if (Object.keys(err.response.data.details).length !== 0) {
      var response_error = JSON.stringify(err.response.data.details);
      if (response_error) {
        response_error = response_error.slice(1, response_error.length-1).replace(/"/g, "")
        response_error = response_error.split(',')
        response_error = response_error.join(".  ");
        return response_error
      }
    } else {
      return err.response.data.message
    }
  }

  render() {
    const { userReady, currentDrill, profileError, redirect } = this.state;

    if (redirect) {
      return <Navigate to={redirect} />
    }

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { profileError.response ? profileError.response.data.message : profileError }
      </div>
    }

    return (
      <div className="container">
        {(userReady) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="card">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis" href="/admin">
                          <img src={homeIcon} alt="Admin Home" className="admin-home-icon" />
                          <span className="visually-hidden">Home</span>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis fw-semibold text-decoration-none" href="/admin">Admin</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        <Link to={{pathname: '/admin/drills'}} target="_self">
                          Drills
                        </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Edycja ćwiczenia
                      </li>
                    </ol>
                  </nav>
                <div className="card-body pb-0">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="text-center">
                        <div className="edit-profile"></div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ms-3">
                        <div>
                          <h4 className="card-title mb-2 mb-2-profile">{currentDrill.name}</h4>
                        </div>
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div>
                              <Form
                                onSubmit={this.handleDrillEdit}
                                ref={c => {
                                  this.form = c;
                                }}
                              >
                                {!this.state.successful && (
                                  <div>
                                    <div className="form-group">
                                      <label htmlFor="name">Nazwa ćwiczenia</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.onChangeName}
                                        validations={[required, drName]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="description">Opis ćwiczenia</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="description"
                                        value={this.state.description}
                                        onChange={this.onChangeDescription}
                                        validations={[required, drDescription]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="time_limit">Limit czasowy</label>
                                      <Input
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        name="time_limit"
                                        value={this.state.time_limit}
                                        onChange={this.onChangeTimeLimit}
                                        validations={[required, drTimeLimit]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="dq">DQ</label>
                                      <Input
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        name="dq"
                                        value={this.state.dq}
                                        onChange={this.onChangeDq}
                                        validations={[drDq]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="approaches">Ilość podejść</label>
                                      <Input
                                        type="number"
                                        step="1"
                                        className="form-control"
                                        name="approaches"
                                        value={this.state.approaches}
                                        onChange={this.onChangeApproaches}
                                        validations={[required, drApproaches]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="mm9">9mm</label>
                                      <Input
                                        type="number"
                                        step="1"
                                        className="form-control"
                                        name="mm9"
                                        value={this.state.mm9}
                                        onChange={this.onChange9mm}
                                        validations={[dr9mm]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="mm556">556mm</label>
                                      <Input
                                        type="number"
                                        step="1"
                                        className="form-control"
                                        name="mm556"
                                        value={this.state.mm556}
                                        onChange={this.onChange556mm}
                                        validations={[dr556mm]}
                                      />
                                    </div>

                                    <p />

                                    {
                                      <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                        {this.state.loading && (
                                          <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Zapisz</span>
                                      </button>
                                    }

                                    {
                                      <button type="button" className="btn btn-primary btn-danger delete-user-button" onClick={this.handleDrillDelete.bind(this, currentDrill.name)}>
                                        Usuń ćwiczenie
                                      </button>
                                    }
                                  </div>
                                )}
                                {(this.state.successful || this.state.error) && (
                                  <div className="form-group">
                                    <div
                                      className={
                                        this.state.error
                                          ? "alert alert-danger"
                                          : "alert alert-success"
                                      }
                                      role="alert"
                                    >
                                      {this.state.message}
                                    </div>
                                  </div>
                                )}
                                <CheckButton
                                  style={{ display: "none" }}
                                  ref={c => {
                                    this.checkBtn = c;
                                  }}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
          : null}
      </div>
    )
  }
}