import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import axios from "axios";
import Swal from 'sweetalert2'
import homeIcon from "../custom_img/home_icon.png";
import '../custom_css/profile_edit.scss';

const API_URL = "https://poborowy.pl/";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        To pole jest wymagane !
      </div>
    );
  }
};

const facName = value => {
  if (value && value.length < 3) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną nazwę obiektu.
      </div>
    );
  }
};

const facAddress = value => {
  if (value && value.length < 5) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawny adres obiektu
      </div>
    );
  }
};

export default class AdminSectionsEdit extends Component {
  constructor(props) {
    super(props);
    this.handleFacilityEdit = this.handleFacilityEdit.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeCoordinates = this.onChangeCoordinates.bind(this);
    this.onChangeLink = this.onChangeLink.bind(this);

    this.state = {
      name: null,
      address: null,
      coordinates: null,
      link: null,
      url_id: window.location.pathname.split('/')[3],
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangeAddress(e) {
    this.setState({
      address: e.target.value
    });
  }

  onChangeCoordinates(e) {
    this.setState({
      coordinates: e.target.value
    });
  }

  onChangeLink(e) {
    this.setState({
      link: e.target.value
    });
  }

  handleFacilityEdit(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
      loading: true
    });

    this.form.validateAll();

    var data = {
      name: this.state.name,
      address: this.state.address,
      coordinates: this.state.coordinates,
      link: this.state.link
    }

    if (this.checkBtn.context._errors.length === 0) {
      axios.put(API_URL + "locations/information/" + this.state.url_id, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      }).then(
        axios_res => {
          if (this.state.name !== axios_res.data.facilities.data.attributes.name) {
            this.setState({
              message: this.handleError(axios_res),
              successful: false,
              error: true,
              loading: false
            });
          } else {
            this.setState({
              loading: false,
              redirect: '/admin/facilities'
            });
            Swal.fire({
              icon: "success",
              title: "Zaktualizowano !",
              text: "Obiekt został poprawnie zaktualizowany"
            });
          }
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage,
            error: true,
            loading: false
          });
        }
      );
    }
  }

  handleFacilityDelete(e) {
    this.setState({
      message: "",
      successful: false,
      loading: false
    });

    Swal.fire({
      title: 'Czy napewno usunąć obiekt ?',
      text: `Usuwasz obiekt "${e}". Nie będziesz w stanie cofnąć tej akcji !`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Usuń',
      cancelButtonText: 'Anuluj'
    }).then((result) => {
      if (result.value) {
        axios.delete(API_URL + "locations/information/" + this.state.url_id, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(res => {
          const redirect = '/admin/facilities'
          this.setState({ redirect });
          Swal.fire({
            icon: "success",
            title: "Usunięto!",
            text: "Obiekt został usunięty z systemu"
          }
        )})
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.handleError(err),
            footer: '<a href>Spróbuj odświeżyć stronę</a>'
          })
        })
      }
    })
  }

  componentDidMount() {
    // GET USER DATA
    axios.get(API_URL + "user/information/" + this.state.currentUserId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.token,
        'Email': this.state.currentUserEmail
      },
    })
    .then(response => {
      this.setState({error: false});
      const currentUser = response.data.users.data.attributes;
      const userReady = true;
      if (currentUser.id_role === 'regular') {
        const redirect = '/login'
        this.setState({ redirect });
      }
      // GET FACILITY DATA
      axios.get(API_URL + "locations/information/" + this.state.url_id, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(res => {
          const currentFacility = res.data.facilities.data.attributes;
          const name =  currentFacility.name;
          const address = currentFacility.address;
          const coordinates = currentFacility.coordinates;
          const link = currentFacility.link;
          this.setState({ currentUser, currentFacility, userReady, name, address, coordinates, link });
        })
        .catch(err => {
          this.setState({
            successful: false,
            profileError: `Nie znaleziono obiektu z ID ${this.state.url_id}`,
            error: true
          });
        })
    })
    .catch(err => {
      if (err.response.status === 401) {
        const redirect = '/login'
        this.setState({ redirect });
      } else if (err.response.status === 404) {
        this.setState({
          successful: false,
          profileError: `Wystąpił błąd lub nie znaleziono użytkownika z ID ${this.state.url_id}`,
          error: true
        });
      } else {
        const profileError = err;
        this.setState({ profileError });
      }
    })
  }

  handleError(err) {
    if (!err.hasOwnProperty('response')) {
      err.response = {
          data: {
            details: "Wystąpił nieoczekiwany błąd."
          }
       }
    }
    if (Object.keys(err.response.data.details).length !== 0) {
      var response_error = JSON.stringify(err.response.data.details);
      if (response_error) {
        response_error = response_error.slice(1, response_error.length-1).replace(/"/g, "")
        response_error = response_error.split(',')
        response_error = response_error.join(".  ");
        return response_error
      }
    } else {
      return err.response.data.message
    }
  }

  render() {
    const { userReady, currentUser, currentFacility, profileError, redirect } = this.state;

    if (redirect) {
      return <Navigate to={redirect} />
    }

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { profileError.response ? profileError.response.data.message : profileError }
      </div>
    }

    return (
      <div className="container">
        {(userReady) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="card">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis" href="/admin">
                          <img src={homeIcon} alt="Admin Home" className="admin-home-icon" />
                          <span className="visually-hidden">Home</span>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis fw-semibold text-decoration-none" href="/admin">Admin</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        <Link to={{pathname: '/admin/facilities'}} target="_self">
                          Obiekty
                        </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Edycja obiektu
                      </li>
                    </ol>
                  </nav>
                <div className="card-body pb-0">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="text-center">
                        <div className="edit-profile"></div>
                        <h4 className="text-primary font-size-20 mt-3 mb-2">{ currentUser.username }</h4>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ms-3">
                        <div>
                          <h4 className="card-title mb-2 mb-2-profile">{currentFacility.name}</h4>
                        </div>
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div>
                              <Form
                                onSubmit={this.handleFacilityEdit}
                                ref={c => {
                                  this.form = c;
                                }}
                              >
                                {!this.state.successful && (
                                  <div>
                                    <div className="form-group">
                                      <label htmlFor="name">Nazwa Obiektu</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.onChangeName}
                                        validations={[required, facName]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="address">Adres Obiektu</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="address"
                                        value={this.state.address}
                                        onChange={this.onChangeAddress}
                                        validations={[required, facAddress]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="coordinates">Koordynaty</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="coordinates"
                                        value={this.state.coordinates}
                                        onChange={this.onChangeCoordinates}
                                        validations={[required]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="link">Odnośnik do mapy<br /><b className="user-edit-dropdown">Dodaj adres rozpoczynający się od embed?pb=...</b></label>
                                      <Input
                                        type="textarea"
                                        className="form-control"
                                        name="link"
                                        value={this.state.link}
                                        onChange={this.onChangeLink}
                                        validations={[required]}
                                      />
                                    </div>
                                    <p />
                                      <iframe src={`https://www.google.com/maps/`+this.state.link} title="Map" width="600" height="450" style={{border:0}} allowfullscreen="" loading="async" referrerpolicy="no-referrer-when-downgrade" />
                                    <p />

                                    {
                                      <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                        {this.state.loading && (
                                          <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Zapisz</span>
                                      </button>
                                    }

                                    {
                                      <button type="button" className="btn btn-primary btn-danger delete-user-button" onClick={this.handleFacilityDelete.bind(this, currentFacility.name)}>
                                        Usuń obiekt
                                      </button>
                                    }
                                  </div>
                                )}
                                {(this.state.successful || this.state.error) && (
                                  <div className="form-group">
                                    <div
                                      className={
                                        this.state.error
                                          ? "alert alert-danger"
                                          : "alert alert-success"
                                      }
                                      role="alert"
                                    >
                                      {this.state.message}
                                    </div>
                                  </div>
                                )}
                                <CheckButton
                                  style={{ display: "none" }}
                                  ref={c => {
                                    this.checkBtn = c;
                                  }}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
          : null}
      </div>
    )
  }
}