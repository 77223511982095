import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import axios from "axios";
import Swal from 'sweetalert2'
import DatePicker from "react-datepicker";
import ReactSelect from 'react-select'
import homeIcon from "../custom_img/home_icon.png";
import '../custom_css/profile_edit.scss';
import 'react-datepicker/dist/react-datepicker.css';

const API_URL = "https://poborowy.pl/";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        To pole jest wymagane !
      </div>
    );
  }
};

const vrName = value => {
  if (value && value.length < 3) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną nazwę testu.
      </div>
    );
  }
};

const vrDescription = value => {
  if (value && value.length < 5) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawny opis testu
      </div>
    );
  }
};

const vrDate = value => {
  if (value && value.toISOString().split('T')[0].match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/) === null) {
    return (
      <div className="alert alert-danger" role="alert">
        Wprowadź poprawną datę w postaci RRRR-MM-DD
      </div>
    );
  }
};

export default class AdminVerificationCreate extends Component {
  constructor(props) {
    super(props);
    this.handleVerificationCreate = this.handleVerificationCreate.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeDrills = this.onChangeDrills.bind(this);
    this.onChangeSection = this.onChangeSection.bind(this);

    this.state = {
      name: null,
      description: null,
      date: null,
      drills: null,
      section: null,
      allDrills: [],
      allSections: [],
      url_id: window.location.pathname.split('/')[3],
      currentUserId: localStorage.getItem('poborowy_user_id'),
      token: JSON.parse(localStorage.getItem('poborowy_token')),
      currentUserEmail: JSON.parse(localStorage.getItem('poborowy_email'))
    };
  }

  nameClassification(drill) {
    if (drill.attributes.mm9 !== null && drill.attributes.mm556 === null) {
      return 'Pistol '
    } else if (drill.attributes.mm9 === null && drill.attributes.mm556 !== null) {
      return 'Rifle '
    } else if (drill.attributes.mm9 !== null && drill.attributes.mm556 !== null) {
      return 'Mix '
    } else if (drill.attributes.mm9 === null && drill.attributes.mm556 === null) {
      return 'Tactical '
    }
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value
    });
  }

  onChangeDate(e) {
    this.setState({
      date: e
    });
  }

  onChangeDrills(e) {
    this.setState({
      drills: e.map(drillopt => drillopt.id)
    });
  }

  onChangeSection(e) {
    this.setState({
      section: e.id
    });
  }

  handleVerificationCreate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    var data = {
      name: this.state.name,
      description: this.state.description,
      test_date: this.state.date ? this.state.date.toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
      drills: this.state.drills,
      section_id: parseInt(this.state.section)
    }

    if (this.checkBtn.context._errors.length === 0) {
      this.setState({loading: true});
      axios.post(API_URL + "/verifications/information", data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      }).then(
        axios_res => {
          this.setState({
            loading: false,
            redirect: '/admin/verifications'
          });
          Swal.fire({
            icon: "success",
            title: "Utworzono !",
            text: "Test został poprawnie utworzony"
          });
        },
        error => {
          const resMessage = error.response.data.details || error.response.data.message || error.toString();
          this.setState({
            successful: false,
            message: resMessage,
            error: true,
            loading: false
          });
        }
      );
    }
  }

  componentDidMount() {
    // GET USER DATA
    axios.get(API_URL + "user/information/" + this.state.currentUserId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.token,
        'Email': this.state.currentUserEmail
      },
    })
    .then(response => {
      this.setState({error: false});
      const currentUser = response.data.users.data.attributes;
      const userReady = true;
      this.setState({ userReady });
      if (currentUser.id_role === 'regular') {
        const redirect = '/login'
        this.setState({ redirect });
      }
      // GET ALL DRILLS DATA
      axios.get(API_URL + "drills/information", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.state.token,
          'Email': this.state.currentUserEmail
        },
      })
      .then(response => {
        const { data: allDrills = [] } = response.data.drills;
        this.setState({ allDrills });
        // GET ALL SECTION DATA
        axios.get(API_URL + "sections/information", {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.state.token,
            'Email': this.state.currentUserEmail
          },
        })
        .then(response => {
          const { data: allSections = [] } = response.data.sections;
          this.setState({ allSections });
        })
        .catch(err => {
          if (err.response.status === 401) {
            const redirect = '/login'
            this.setState({ redirect });
          } else {
            const profileError = err;
            this.setState({ profileError });
          }
        })
      })
      .catch(err => {
        if (err.response.status === 401) {
          const redirect = '/login'
          this.setState({ redirect });
        } else {
          const profileError = err;
          this.setState({ profileError });
        }
      })
    })
    .catch(err => {
      if (err.response.status === 401) {
        const redirect = '/login'
        this.setState({ redirect });
      } else if (err.response.status === 404) {
        this.setState({
          successful: false,
          profileError: `Wystąpił błąd lub nie znaleziono użytkownika z ID ${this.state.url_id}`,
          error: true
        });
      } else {
        const profileError = err;
        this.setState({ profileError });
      }
    })
  }

  handleError(err) {
    if (!err.hasOwnProperty('response')) {
      err.response = {
          data: {
            details: "Wystąpił nieoczekiwany błąd."
          }
       }
    }
    if (Object.keys(err.response.data.details).length !== 0) {
      var response_error = JSON.stringify(err.response.data.details);
      if (response_error) {
        response_error = response_error.slice(1, response_error.length-1).replace(/"/g, "")
        response_error = response_error.split(',')
        response_error = response_error.join(".  ");
        return response_error
      }
    } else {
      return err.response.data.message
    }
  }

  render() {
    const { userReady, profileError, redirect, date, allDrills, allSections } = this.state;
    const pickDate = date || new Date();
    const days = ['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob']
    const months = [
      "Styczeń",
      "Luty",
      "Marzec",
      "Kwiecień",
      "Maj",
      "Czerwiec",
      "Lipiec",
      "Sierpień",
      "Wrzesień",
      "Październik",
      "Listopad",
      "Grudzień",
    ]
    const locale = {
      localize: {
        day: n => days[n],
        month: n => months[n]
      },
      formatLong: {
        date: () => 'yyyy-mm-dd'
      }
    }

    if (redirect) {
      return <Navigate to={redirect} />
    }

    if (profileError) {
      return <div className="alert alert-danger" role="alert">
        { profileError.response ? profileError.response.data.message : profileError }
      </div>
    }

    return (
      <div className="container">
        {(userReady) ?
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="card">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-chevron p-3 bg-body-tertiary rounded-3">
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis" href="/admin">
                          <img src={homeIcon} alt="Admin Home" className="admin-home-icon" />
                          <span className="visually-hidden">Home</span>
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a className="link-body-emphasis fw-semibold text-decoration-none" href="/admin">Admin</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        <Link to={{pathname: '/admin/verifications'}} target="_self">
                          Testy
                        </Link>  
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Nowy test
                      </li>
                    </ol>
                  </nav>
                <div className="card-body pb-0">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="text-center">
                        <div className="edit-profile"></div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ms-3">
                        <div>
                          <h4 className="card-title mb-2 mb-2-profile">Nowy test</h4>
                        </div>
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div>
                              <Form
                                onSubmit={this.handleVerificationCreate}
                                ref={c => {
                                  this.form = c;
                                }}
                              >
                                {!this.state.successful && (
                                  <div>
                                    <div className="form-group">
                                      <label htmlFor="name">Nazwa testu</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.onChangeName}
                                        validations={[required, vrName]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="description">Opis testu</label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="description"
                                        value={this.state.description}
                                        onChange={this.onChangeDescription}
                                        validations={[required, vrDescription]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="date">Data testu</label>
                                      <DatePicker
                                        showToday
                                        name="date"
                                        dateFormat="yyyy-MM-dd"
                                        locale={locale}
                                        selected={pickDate}
                                        onChange={this.onChangeDate}
                                        validations={[vrDate]}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="drills">Drille</label>
                                      <ReactSelect 
                                        className="basic-multi-select"
                                        name="drills"
                                        onChange={this.onChangeDrills} 
                                        options={allDrills} 
                                        isMulti 
                                        getOptionLabel={(allDrills) => this.nameClassification(allDrills) + allDrills.attributes.name}
                                        getOptionValue={(allDrills) => allDrills.id}
                                        required={true}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label htmlFor="section">Sekcja</label>
                                      <ReactSelect 
                                        className="basic-multi-select"
                                        name="section"
                                        onChange={this.onChangeSection} 
                                        options={allSections} 
                                        getOptionLabel={(allSections) => allSections.attributes.name}
                                        getOptionValue={(allSections) => allSections.id}
                                        required={true}
                                      />
                                    </div>
                                    <p />

                                    {
                                      <button className="btn btn-primary btn-block" disabled={this.state.loading}>
                                        {this.state.loading && (
                                          <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Zapisz</span>
                                      </button>
                                    }
                                  </div>
                                )}
                                {(this.state.successful || this.state.error) && (
                                  <div className="form-group">
                                    <div
                                      className={
                                        this.state.error
                                          ? "alert alert-danger"
                                          : "alert alert-success"
                                      }
                                      role="alert"
                                    >
                                      {this.state.message}
                                    </div>
                                  </div>
                                )}
                                <CheckButton
                                  style={{ display: "none" }}
                                  ref={c => {
                                    this.checkBtn = c;
                                  }}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
          : null}
      </div>
    )
  }
}